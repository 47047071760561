// Libs
import React, { useCallback, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { MdMenu } from 'react-icons/md'
import Swal from 'sweetalert2'

// Redux
import { dashboardListR } from '../../store/modules'

// Api
import api from '../../api'

// Styles
import { Container, Menu, Statistics, Divider, Avatar } from './styles'

export const Header = ({ toggle, toggleState }) => {
  const history = useHistory()

  // Redux
  const dispatch = useDispatch()
  const { name } = useSelector(state => state.auth)
  const { stats } = useSelector(state => state.dashboard)

  useEffect(() => {
    if (!stats) dispatch(dashboardListR())
  }, [stats, dispatch])

  const logOut = useCallback(() => {
    Swal.fire({
      icon: 'warning',
      title: 'Quer mesmo sair?',
      showCancelButton: true,
      confirmButtonColor: '#2d9a68',
      confirmButtonText: 'Sair',
      cancelButtonText: 'Não',
      cancelButtonColor: '#121214'
    }).then(result => {
      if (result.isConfirmed) {
        api.auth.resetAuthData()
        return history.go('/')
      }
    })
  }, [history])

  const HeaderMemo = useMemo(() => {
    if (stats) {
      return (
        <>
          <Statistics>
            <div>
              <p>
                Alunos <strong>{Number(stats.usersCount)}</strong>
              </p>
            </div>
            <Divider />
            <div>
              <p>
                Cursos <strong>{Number(stats.coursesCount)}</strong>
              </p>
            </div>
            <Divider />
            <div>
              <p>
                Aulas <strong>{Number(stats.lessonsCount)}</strong>
              </p>
            </div>
          </Statistics>
        </>
      )
    }
  }, [stats])

  return (
    <Container>
      <Menu
        toggleState={toggleState}
        onClick={() => {
          toggle()
        }}
        onKeyDown={() => {
          toggle()
        }}
      >
        <MdMenu size={24} />
      </Menu>
      {HeaderMemo}
      <Avatar>
        <p>
          {name && name}{' '}
          <strong
            onClick={() => {
              logOut()
            }}
          >
            Sair
          </strong>
        </p>
      </Avatar>
    </Container>
  )
}
