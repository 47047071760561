import React from 'react'
import PropTypes from 'prop-types'

import { Container } from './styles'

import { Spinner } from '../Spinner'

export const Button = ({ type, loading, children, ...rest }) => {
  return (
    <Container type={type} {...rest}>
      {loading ? <Spinner /> : children}
    </Container>
  )
}

Button.defaultProps = {
  type: 'submit',
  loading: false
}
Button.propTypes = {
  type: PropTypes.string,
  loading: PropTypes.bool
}
