// Libs
import React, { useRef, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form } from '@unform/web'
import { MdLoop } from 'react-icons/md'

// Components
import { Input, Button } from '../../../../../../components'

// Redux
import { userUpdateR, getCepR } from '../../../../../../store/modules'

// Responsive
import { Row, Column } from '../../../../../../styles'

// Styles
import { Container, Content } from './styles'

// Validations
import { usersUpdateSchema } from '../../../../../../validations'

export const ModalEdit = ({ data }) => {
  // Hooks
  const formRef = useRef(null)

  // Redux
  const dispatch = useDispatch()
  const { loading, validationsErrors } = useSelector(state => state.users)
  const { cep } = useSelector(state => state.utils)

  const setCep = useCallback(
    value => {
      value.length >= 8 && dispatch(getCepR({ cep: value }))
    },
    [dispatch]
  )
  useEffect(() => {
    const setInfos = () => {
      if (cep) {
        formRef.current.setData({
          address: cep.logradouro,
          city: cep.localidade,
          state: cep.uf,
          country: 'Brasil'
        })
      }
    }
    return setInfos()
  }, [cep])

  // Submit form Request
  const handleSubmit = useCallback(
    async submitData => {
      try {
        formRef.current.setErrors({})

        await usersUpdateSchema.validate(submitData, {
          abortEarly: false
        })

        const {
          name,
          email,
          cpf: cleanCpf,
          cep,
          city,
          state,
          address,
          country
        } = submitData
        const cpf = cleanCpf.replace('-', '').replace('.', '').replace('.', '')
        dispatch(
          userUpdateR({
            id: data.id,
            name,
            email,
            cpf,
            cep,
            address,
            city,
            state,
            country
          })
        )
      } catch (err) {
        const validationErrors = {}
        if (err) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message
          })
          formRef.current.setErrors(validationErrors)
        }
      }
    },
    [dispatch, data.id]
  )

  useEffect(() => {
    const setInfos = () => {
      if (data) {
        formRef.current.setData({
          name: data.name,
          email: data.email,
          cpf: data.cpf,
          cep: data.cep,
          address: data.address,
          city: data.city,
          state: data.state,
          country: data.country
        })
      }
    }
    return setInfos()
  }, [data])

  return (
    <Container>
      <Content>
        <h5>
          Editando o Usuário <strong>{data.name}</strong>
        </h5>
      </Content>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Row>
          <Column xs="12" sm="5" md="5" lg="6">
            <Input
              name="name"
              type="text"
              label="Nome"
              placeholder="Seu Nome..."
            />
          </Column>
          <Column xs="12" sm="5" md="5" lg="6">
            <Input
              name="email"
              type="text"
              label="Email"
              placeholder="Seu Email..."
            />
          </Column>
          <Column xs="12" sm="5" md="5" lg="6">
            <Input
              name="cpf"
              type="text"
              label="Cpf"
              placeholder="Seu Cpf..."
              unform={false}
              mask="999.999.999-99"
            />
          </Column>
          <Column xs="12" sm="5" md="5" lg="6">
            <Input
              name="cep"
              type="text"
              aria-label="cep"
              placeholder="Seu Cep..."
              label="Cep"
              icon="FaGlobeAmericas"
              autoComplete="off"
              onChange={e => setCep(e.target.value)}
            />
          </Column>
          <Column xs="12" sm="5" md="5" lg="6">
            <Input
              name="address"
              type="text"
              label="Endereço"
              placeholder="Seu Endereço..."
            />
          </Column>
          <Column xs="12" sm="5" md="5" lg="6">
            <Input
              name="city"
              type="text"
              label="Cidade"
              placeholder="Sua Cidade..."
            />
          </Column>
          <Column xs="12" sm="5" md="5" lg="6">
            <Input
              name="state"
              type="text"
              label="Estado"
              placeholder="Seu Estado..."
            />
          </Column>
          <Column xs="12" sm="5" md="5" lg="6">
            <Input
              name="country"
              type="text"
              label="País"
              placeholder="Seu País..."
            />
          </Column>
          <Column xs="12" sm="5" md="5" lg="6" className="padding-top">
            {validationsErrors &&
              validationsErrors.map((e, i) => <p key={i}>{e}</p>)}
          </Column>
          <Column xs="12" sm="5" md="5" lg="12">
            <Button loading={loading} type="submit">
              <MdLoop size={24} /> Atualizar
            </Button>
          </Column>
        </Row>
      </Form>
    </Container>
  )
}
