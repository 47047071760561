// List
const BANNER_LIST_R = '@banners/BANNER_LIST_R'
const BANNER_LIST_S = '@banners/BANNER_LIST_S'
const BANNER_LIST_E = '@banners/BANNER_LIST_E'

const BANNER_CREATE_OR_UPDATE_R = '@banners/BANNER_CREATE_OR_UPDATE_R'
const BANNER_CREATE_OR_UPDATE_S = '@banners/BANNER_CREATE_OR_UPDATE_S'
const BANNER_CREATE_OR_UPDATE_E = '@banners/BANNER_CREATE_OR_UPDATE_E'
const BANNER_CREATE_OR_UPDATE_V = '@banners/BANNER_CREATE_OR_UPDATE_V'

export {
  BANNER_LIST_R,
  BANNER_LIST_S,
  BANNER_LIST_E,
  BANNER_CREATE_OR_UPDATE_R,
  BANNER_CREATE_OR_UPDATE_S,
  BANNER_CREATE_OR_UPDATE_E,
  BANNER_CREATE_OR_UPDATE_V
}
