import React from 'react'

// SubComponents
import { ModalEdit } from '../modal/edit'
import { ModalCreate } from '../modal/create'

export const UserModal = ({ modal, data }) => {
  return data ? (
    <ModalEdit modal={modal} data={data} />
  ) : (
    <ModalCreate modal={modal} />
  )
}
