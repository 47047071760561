import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  span {
    display: block;
    width: 100%;
    min-height: 16px;
    font-size: 14px;
    color: rgb(135,134,139);
    margin-bottom: 8px;
  }

  label {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    color: rgb(168, 168, 179);
    cursor: pointer;
    font-size: 14px;
    user-select: none;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      :checked:enabled ~ span {
        border-width: 0px;
        background-color: #2d9a68;
      }
      :checked ~ span::after {
        display: block;
        opacity: 1;
      }
    }

    span {
      height: 20px;
      width: 20px;
      position: relative;
      top: 0px;
      left: 0px;
      background-color: rgb(18, 18, 20);
      border: 2px solid rgb(50, 50, 56);
      border-radius: 50%;
      margin-right: 8px;
      transition: background-color 0.2s ease 0s;

      :after {
        content: '';
        top: 6px;
        left: 6px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: white;
        opacity: 0;
        position: absolute;
        transition: opacity 0.2s ease 0s;
      }
    }
  }
`
