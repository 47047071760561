import {
  LESSONS_LIST_R,
  LESSONS_LIST_S,
  LESSONS_LIST_E,
  LESSON_CREATE_R,
  LESSON_CREATE_S,
  LESSON_CREATE_E,
  LESSON_CREATE_V,
  LESSON_UPDATE_R,
  LESSON_UPDATE_S,
  LESSON_UPDATE_E,
  LESSON_UPDATE_V,
  LESSON_DELETE_R,
  LESSON_DELETE_S,
  LESSON_DELETE_E
} from './types'

export const lessonsListR = payload => ({
  type: LESSONS_LIST_R,
  payload
})

export const lessonsListS = payload => ({
  type: LESSONS_LIST_S,
  payload
})

export const lessonsListE = () => ({
  type: LESSONS_LIST_E
})

export const lessonCreateR = payload => ({
  type: LESSON_CREATE_R,
  payload
})

export const lessonCreateS = payload => ({
  type: LESSON_CREATE_S,
  payload
})

export const lessonCreateE = () => ({
  type: LESSON_CREATE_E
})

export const lessonCreateV = payload => ({
  type: LESSON_CREATE_V,
  payload
})

export const lessonUpdateR = payload => ({
  type: LESSON_UPDATE_R,
  payload
})

export const lessonUpdateS = () => ({
  type: LESSON_UPDATE_S
})

export const lessonUpdateE = () => ({
  type: LESSON_UPDATE_E
})

export const lessonUpdateV = payload => ({
  type: LESSON_UPDATE_V,
  payload
})

export const lessonDeleteR = payload => ({
  type: LESSON_DELETE_R,
  payload
})

export const lessonDeleteS = payload => ({
  type: LESSON_DELETE_S,
  payload
})

export const lessonDeleteE = () => ({
  type: LESSON_DELETE_E
})
