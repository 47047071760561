import React, { useMemo } from 'react'

// Libs
import { Switch } from 'react-router-dom'

// Component Routes
import { RouteWrapper } from './Route'

// Pages
import { Authentication, Dashboard, Users, Courses, Lessons } from '../pages'

export const Routes = () => {
  const RouteMemo = useMemo(() => {
    return (
      <Switch>
        {/* Authentication Route */}
        <RouteWrapper path="/" exact component={Authentication} />

        {/* Dashboard Route */}
        <RouteWrapper path="/dashboard" exact component={Dashboard} isPrivate />
        <RouteWrapper path="/users" exact component={Users} isPrivate />
        <RouteWrapper path="/courses" exact component={Courses} isPrivate />
        <RouteWrapper path="/lessons" exact component={Lessons} isPrivate />
      </Switch>
    )
  }, [])

  return RouteMemo
}
