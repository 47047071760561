import { SIGN_IN_R, SIGN_IN_S, SIGN_IN_E, SIGN_IN_V, SIGN_OUT } from './types'

export const signInR = payload => ({
  type: SIGN_IN_R,
  payload
})

export const signInS = payload => ({
  type: SIGN_IN_S,
  payload
})

export const signInE = () => ({
  type: SIGN_IN_E
})

export const signInV = payload => ({
  type: SIGN_IN_V,
  payload
})

export const signOut = () => ({
  type: SIGN_OUT
})
