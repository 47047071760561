import { GET_CEP_R, GET_CEP_S, GET_CEP_E } from './types'

export const getCepR = payload => ({
  type: GET_CEP_R,
  payload
})

export const getCepS = payload => ({
  type: GET_CEP_S,
  payload
})

export const getCepE = () => ({
  type: GET_CEP_E
})
