// Libs
import { all, takeLatest, call, put } from 'redux-saga/effects'
import { toast } from 'react-toastify'

// Api
import api from '../../../api'

// Types
import {
  COURSES_LIST_R,
  COURSE_CREATE_R,
  COURSE_UPDATE_R,
  COURSE_DELETE_R
} from './types'

// Actions
import {
  coursesListS,
  coursesListE,
  courseCreateS,
  courseCreateE,
  courseCreateV,
  courseUpdateS,
  courseUpdateE,
  courseUpdateV,
  courseDeleteS,
  courseDeleteE
} from './actions'

// Utils
import { error } from '../../../utils'

export function* listCourses() {
  try {
    const res = yield call(api.courses.list)

    const {
      data: {
        data,
        status: { code }
      }
    } = res

    if ([200].includes(code)) {
      return yield put(coursesListS({ courses: data }))
    }
  } catch (err) {
    if (err.response) {
      const {
        data: {
          status: { code, message }
        }
      } = err.response

      if ([400].includes(code)) {
        toast.error(`Erro na listagem. ${message}`, error)
        return yield put(coursesListE())
      }

      if ([500].includes(code)) {
        toast.error('Erro na listagem. tente novamente mais tarde!', error)
        return yield put(coursesListE())
      }
    } else {
      toast.error('Erro na listagem. tente novamente mais tarde!', error)
      return yield put(coursesListE())
    }
  }
}

export function* createCourse({ payload }) {
  try {
    const { title, description, thumbnail, banner, order, group } = payload

    const res = yield call(api.courses.create, {
      title,
      description,
      thumbnail,
      banner,
      order,
      group
    })

    const {
      data: {
        data: courseData,
        status: { code }
      }
    } = res
    if ([201].includes(code)) {
      toast.success(`Curso Criado com Sucesso ${courseData.title}`)
      yield put(courseCreateS())
      return yield call(listCourses)
    }
  } catch (err) {
    if (err.response) {
      const {
        data: {
          data: courseData,
          status: { code, message }
        }
      } = err.response
      if ([400].includes(code)) {
        if (courseData) {
          const validations = courseData.map(e => e)
          toast.error('Erro ao criar um Curso. Verifique seus dados', error)
          return yield put(courseCreateV({ errors: validations }))
        }
        toast.error(`Erro ao criar um Curso. ${message}`, error)
        return yield put(courseCreateE())
      }

      if ([500].includes(code)) {
        toast.error(
          'Erro ao criar um Curso. tente novamente mais tarde!',
          error
        )
        return yield put(courseCreateE())
      }
    } else {
      toast.error('Erro ao criar um Curso. tente novamente mais tarde!', error)
      return yield put(courseCreateE())
    }
  }
}

export function* updateCourse({ payload }) {
  try {
    const { id, title, description, thumbnail, banner, order, group } = payload
    const res = yield call(api.courses.update, {
      id,
      title,
      description,
      thumbnail,
      banner,
      order,
      group
    })

    const {
      data: {
        data: courseData,
        status: { code }
      }
    } = res

    if ([200].includes(code)) {
      toast.success(`Curso ${courseData.title} Atualizado com Sucesso!`)
      yield put(courseUpdateS())
      return yield call(listCourses)
    }
  } catch (err) {
    if (err.response) {
      const {
        data: {
          data: courseData,
          status: { code, message }
        }
      } = err.response
      if ([400].includes(code)) {
        if (courseData) {
          const validations = courseData.map(e => e)
          toast.error('Erro ao atualizar um Curso. Verifique seus dados', error)
          return yield put(courseUpdateV({ errors: validations }))
        }
        toast.error(`Erro ao atualizar um Curso. ${message}`, error)
        return yield put(courseUpdateE())
      }

      if ([500].includes(code)) {
        toast.error(
          'Erro ao atualizar um Curso. tente novamente mais tarde!',
          error
        )
        return yield put(courseUpdateE())
      }
    } else {
      toast.error(
        'Erro ao atualizar um Curso. tente novamente mais tarde!',
        error
      )
      return yield put(courseUpdateE())
    }
  }
}

export function* deleteCourse({ payload }) {
  try {
    const { id } = payload
    const res = yield call(api.courses.deleteU, { id })

    const {
      data: {
        status: { code }
      }
    } = res

    if ([203].includes(code)) {
      toast.success('Curso Deletado com Sucesso')
      return yield put(courseDeleteS({ id }))
    }
  } catch (err) {
    if (err.response) {
      const {
        data: {
          status: { code, message }
        }
      } = err.response
      if ([400].includes(code)) {
        toast.error(`Erro ao deletar um Curso. ${message}`, error)
        return yield put(courseDeleteS())
      }

      if ([500].includes(code)) {
        toast.error(
          'Erro ao deletar um Curso. tente novamente mais tarde!',
          error
        )
        return yield put(courseDeleteE())
      }
    } else {
      toast.error(
        'Erro ao deletar um Curso. tente novamente mais tarde!',
        error
      )
      return yield put(courseDeleteE())
    }
  }
}

export default all([
  takeLatest(COURSES_LIST_R, listCourses),
  takeLatest(COURSE_CREATE_R, createCourse),
  takeLatest(COURSE_UPDATE_R, updateCourse),
  takeLatest(COURSE_DELETE_R, deleteCourse)
])
