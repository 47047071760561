import {
  BANNER_LIST_R,
  BANNER_LIST_S,
  BANNER_LIST_E,
  BANNER_CREATE_OR_UPDATE_R,
  BANNER_CREATE_OR_UPDATE_S,
  BANNER_CREATE_OR_UPDATE_E,
  BANNER_CREATE_OR_UPDATE_V
} from './types'

// List
export const bannerListR = () => ({
  type: BANNER_LIST_R
})
export const bannerListS = payload => ({
  type: BANNER_LIST_S,
  payload
})
export const bannerListE = () => ({
  type: BANNER_LIST_E
})

// Create or Update
export const bannerCreateOrUpdateR = payload => ({
  type: BANNER_CREATE_OR_UPDATE_R,
  payload
})

export const bannerCreateOrUpdateS = () => ({
  type: BANNER_CREATE_OR_UPDATE_S
})

export const bannerCreateOrUpdateE = () => ({
  type: BANNER_CREATE_OR_UPDATE_E
})
export const bannerCreateOrUpdateV = payload => ({
  type: BANNER_CREATE_OR_UPDATE_V,
  payload
})
