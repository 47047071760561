import * as Yup from 'yup'

export const usersSchema = Yup.object().shape({
  email: Yup.string()
    .email('Insira um email válido')
    .required('E-mail é obrigatório'),
  name: Yup.string()
    .matches(
      /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g,
      'Digite seu nome completo'
    )
    .required('Nome é obrigatório'),
  cpf: Yup.string().required('Cpf é obrigatório'),
  city: Yup.string().required('Cidade é obrigatório'),
  state: Yup.string().required('Estado é obrigatório'),
  address: Yup.string().required('Endereço é obrigatório'),
  country: Yup.string().required('País é obrigatório'),
  password: Yup.string()
    .min(6, 'No mínimo, 6 caracteres')
    .required('Senha é obrigatório'),
  passwordConfirm: Yup.string()
    .min(6, 'No mínimo, 6 caracteres')
    .required('Confirmação de senha é obrigatório')
    .oneOf([Yup.ref('password'), null], 'Senhas não conferem.')
})

export const usersUpdateSchema = Yup.object().shape({
  email: Yup.string()
    .email('Insira um email válido')
    .required('E-mail é obrigatório'),
  name: Yup.string()
    .matches(
      /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g,
      'Digite seu nome completo'
    )
    .required('Nome é obrigatório'),
  cpf: Yup.string().required('Cpf é obrigatório'),
  city: Yup.string().required('Cidade é obrigatório'),
  state: Yup.string().required('Estado é obrigatório'),
  address: Yup.string().required('Endereço é obrigatório'),
  country: Yup.string().required('País é obrigatório')
})
