import * as Yup from 'yup'

export const coursesSchema = Yup.object().shape({
  title: Yup.string().required('Título é obrigatório'),
  description: Yup.string().required('Descrição é obrigatório'),
  thumbnail: Yup.string().required('Thumbnail é obrigatório'),
  order: Yup.string().required('Ordem é obrigatório'),
  group: Yup.string().ensure().required('Aulas é obrigatório')
})

export const courseUpdateSchema = Yup.object().shape({
  title: Yup.string().required('Título é obrigatório'),
  description: Yup.string().required('Descrição é obrigatório'),
  thumbnail: Yup.string().required('Thumbnail é obrigatório'),
  order: Yup.string().required('Ordem é obrigatório')
})
