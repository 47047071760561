import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 82px 1fr 50px;
  grid-template-areas:
      'header'
      'main'
      'footer';
  height: 100vh;
  overflow-x: hidden;
  
  @media only screen and (min-width: 1025px) {
    grid-template-columns: 240px calc(100% - 240px);
    grid-template-areas:
      'sidebar header'
      'sidebar main'
      'sidebar footer';
  }

  main {
    background: var(--color-background-light);
    overflow-y: auto;
    display: flex;
  }
`
