const LESSONS_LIST_R = '@lessons/LESSONS_LIST_R'
const LESSONS_LIST_S = '@lessons/LESSONS_LIST_S'
const LESSONS_LIST_E = '@lessons/LESSONS_LIST_E'
const LESSON_CREATE_R = '@lessons/LESSON_CREATE_R'
const LESSON_CREATE_S = '@lessons/LESSON_CREATE_S'
const LESSON_CREATE_E = '@lessons/LESSON_CREATE_E'
const LESSON_CREATE_V = '@lessons/LESSON_CREATE_V'
const LESSON_UPDATE_R = '@lessons/LESSON_UPDATE_R'
const LESSON_UPDATE_S = '@lessons/LESSON_UPDATE_S'
const LESSON_UPDATE_E = '@lessons/LESSON_UPDATE_E'
const LESSON_UPDATE_V = '@lessons/LESSON_UPDATE_V'
const LESSON_DELETE_R = '@lessons/LESSON_DELETE_R'
const LESSON_DELETE_S = '@lessons/LESSON_DELETE_S'
const LESSON_DELETE_E = '@lessons/LESSON_DELETE_E'

export {
  LESSONS_LIST_R,
  LESSONS_LIST_S,
  LESSONS_LIST_E,
  LESSON_CREATE_R,
  LESSON_CREATE_S,
  LESSON_CREATE_E,
  LESSON_CREATE_V,
  LESSON_UPDATE_R,
  LESSON_UPDATE_S,
  LESSON_UPDATE_E,
  LESSON_UPDATE_V,
  LESSON_DELETE_R,
  LESSON_DELETE_S,
  LESSON_DELETE_E
}
