import { produce } from 'immer'

import { SIGN_IN_R, SIGN_IN_S, SIGN_IN_E, SIGN_IN_V, SIGN_OUT } from './types'

const initialState = {
  token: null,
  name: null,
  loading: false,
  validationsErrors: null
}

export const auth = (state = initialState, { type, payload }) => {
  return produce(state, draft => {
    switch (type) {
      case SIGN_IN_R: {
        draft.loading = true
        draft.validationsErrors = null
        break
      }
      case SIGN_IN_S: {
        draft.loading = false
        draft.validationsErrors = null
        draft.token = payload.token
        draft.name = payload.name
        break
      }
      case SIGN_IN_E: {
        draft.loading = false
        break
      }
      case SIGN_IN_V: {
        draft.loading = false
        draft.validationsErrors = payload.errors
        break
      }
      case SIGN_OUT: {
        draft.loading = false
        break
      }
      default:
        return state
    }
  })
}
