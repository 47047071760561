// Libs
import { all, takeLatest, call, put } from 'redux-saga/effects'
import { toast } from 'react-toastify'

// Api
import api from '../../../api'

// Types
import {
  LESSONS_LIST_R,
  LESSON_CREATE_R,
  LESSON_UPDATE_R,
  LESSON_DELETE_R
} from './types'

// Actions
import {
  lessonsListS,
  lessonsListE,
  lessonCreateS,
  lessonCreateE,
  lessonCreateV,
  lessonUpdateS,
  lessonUpdateE,
  lessonUpdateV,
  lessonDeleteS,
  lessonDeleteE
} from './actions'

// Utils
import { error } from '../../../utils'

export function* listLessons() {
  try {
    const res = yield call(api.lessons.list)

    const {
      data: {
        data,
        status: { code }
      }
    } = res

    if ([200].includes(code)) {
      return yield put(lessonsListS({ lessons: data }))
    }
  } catch (err) {
    if (err.response) {
      const {
        data: {
          status: { code, message }
        }
      } = err.response

      if ([400].includes(code)) {
        toast.error(`Erro na listagem. ${message}`, error)
        return yield put(lessonsListE())
      }

      if ([500].includes(code)) {
        toast.error('Erro na listagem. tente novamente mais tarde!', error)
        return yield put(lessonsListE())
      }
    } else {
      toast.error('Erro na listagem. tente novamente mais tarde!', error)
      return yield put(lessonsListE())
    }
  }
}

export function* createLesson({ payload }) {
  try {
    const { type, content } = payload

    const res = yield call(api.lessons.create, {
      type,
      content
    })

    const {
      data: {
        data: lessonData,
        status: { code }
      }
    } = res
    if ([201].includes(code)) {
      toast.success(`Aula Criada com Sucesso ${lessonData.content.title}`)
      yield put(lessonCreateS())
      return yield call(listLessons)
    }
  } catch (err) {
    if (err.response) {
      const {
        data: {
          data: lessonData,
          status: { code, message }
        }
      } = err.response
      if ([400].includes(code)) {
        if (lessonData) {
          const validations = lessonData.map(e => e)
          toast.error('Erro ao criar uma Aula. Verifique seus dados', error)
          return yield put(lessonCreateV({ errors: validations }))
        }
        toast.error(`Erro ao criar uma Aula. ${message}`, error)
        return yield put(lessonCreateE())
      }

      if ([500].includes(code)) {
        toast.error(
          'Erro ao criar uma Aula. tente novamente mais tarde!',
          error
        )
        return yield put(lessonCreateE())
      }
    } else {
      toast.error('Erro ao criar uma Aula. tente novamente mais tarde!', error)
      return yield put(lessonCreateE())
    }
  }
}

export function* updateLesson({ payload }) {
  try {
    const { id, type, content } = payload
    const res = yield call(api.lessons.update, {
      id,
      type,
      content
    })

    const {
      data: {
        data: lessonData,
        status: { code }
      }
    } = res

    if ([200].includes(code)) {
      toast.success(`Aula ${lessonData.content.title} Atualizada com Sucesso!`)
      yield put(lessonUpdateS())
      return yield call(listLessons)
    }
  } catch (err) {
    if (err.response) {
      const {
        data: {
          data: lessonData,
          status: { code, message }
        }
      } = err.response
      if ([400].includes(code)) {
        if (lessonData) {
          const validations = lessonData.map(e => e)
          toast.error('Erro ao atualizar uma Aula. Verifique seus dados', error)
          return yield put(lessonUpdateV({ errors: validations }))
        }
        toast.error(`Erro ao atualizar uma Aula. ${message}`, error)
        return yield put(lessonUpdateE())
      }

      if ([500].includes(code)) {
        toast.error(
          'Erro ao atualizar uma Aula. tente novamente mais tarde!',
          error
        )
        return yield put(lessonUpdateE())
      }
    } else {
      toast.error(
        'Erro ao atualizar uma Aula. tente novamente mais tarde!',
        error
      )
      return yield put(lessonUpdateE())
    }
  }
}

export function* deleteLesson({ payload }) {
  try {
    const { id } = payload
    const res = yield call(api.lessons.deleteU, { id })

    const {
      data: {
        status: { code }
      }
    } = res

    if ([203].includes(code)) {
      toast.success('Aula Deletada com Sucesso')
      return yield put(lessonDeleteS({ id }))
    }
  } catch (err) {
    if (err.response) {
      const {
        data: {
          status: { code, message }
        }
      } = err.response
      if ([400].includes(code)) {
        toast.error(`Erro ao deletar uma Aula. ${message}`, error)
        return yield put(lessonDeleteS())
      }

      if ([500].includes(code)) {
        toast.error(
          'Erro ao deletar uma Aula. tente novamente mais tarde!',
          error
        )
        return yield put(lessonDeleteE())
      }
    } else {
      toast.error(
        'Erro ao deletar uma Aula. tente novamente mais tarde!',
        error
      )
      return yield put(lessonDeleteE())
    }
  }
}

export default all([
  takeLatest(LESSONS_LIST_R, listLessons),
  takeLatest(LESSON_CREATE_R, createLesson),
  takeLatest(LESSON_UPDATE_R, updateLesson),
  takeLatest(LESSON_DELETE_R, deleteLesson)
])
