import styled, { keyframes } from 'styled-components'

const ballScalePulse = keyframes`
    0%,
    100% {
      transform: scale(0);
    }
    50% {
      transform: scale(1);
    }
`
export const Container = styled.span`
  display: block;
  width: 20px;
  height: 20px;
  position: relative;
  margin: 0px auto;

  &::after {
    animation-delay: -1s;
  }

  ::before,
  ::after {
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
    opacity: 0.3;
    position: absolute;
    top: 0px;
    left: 0px;
    animation: ${ballScalePulse} 2s ease-in-out infinite;
  }
`
