import React, { useMemo } from 'react'

// Styles
import { Container } from './styles'

export const Expandable = ({ data }) => {
  const Table = useMemo(() => {
    if (data) {
      return (
        <table>
          <thead>
            <tr>
              <th>Título</th>
              <th>Tipo</th>
              <th>Conteúdo</th>
              <th>Anexos</th>
              <th>Plataforma</th>
              <th>Ordem</th>
            </tr>
          </thead>
          <tbody>
            {data.group.lessons.map(e => (
              <tr key={e.content.id}>
                <td>
                  <div>{e.content.title}</div>
                </td>
                <td>
                  <div>{e.type}</div>
                </td>
                <td>
                  <div>{e.content.resource}</div>
                </td>
                <td>
                  <div>{e.content.attachments}</div>
                </td>
                <td>
                  <div>{e.content.platform}</div>
                </td>
                <td>
                  <div>{e.content.order}</div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )
    }
  }, [data])
  return <Container>{Table}</Container>
}
