import { produce } from 'immer'

import { GET_CEP_R, GET_CEP_S, GET_CEP_E } from './types'

const initialState = {
  cep: null,
  loading: false
}

export const utils = (state = initialState, { type, payload }) => {
  return produce(state, draft => {
    switch (type) {
      case GET_CEP_R: {
        draft.loading = true
        break
      }
      case GET_CEP_S: {
        draft.cep = payload.cep
        draft.loading = false
        break
      }
      case GET_CEP_E: {
        draft.loading = false
        break
      }
      default:
        return state
    }
  })
}
