import styled from 'styled-components'

export const Container = styled.div`
  display: inline-flex;
  justify-content: center;
  background: rgb(41, 41, 46);
  border-radius: 5px;
  padding: 8px;
`

export const ButtonToggle = styled.button`
  letter-spacing: 0.2px;
  color: rgb(225, 225, 230);
  padding: 8px 16px;
  cursor: default;
  background: ${({ active }) =>
    active ? 'var(--color-green-light)' : 'transparent'};
  border-radius: 5px;
  border: 0px;
  font-size: 11.5px;
  font-weight: bold;
  transition: background 0.2s ease 0s;
  text-transform: uppercase;

  & + button {
    margin-left: 8px;
  }
`
