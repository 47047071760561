import styled from 'styled-components'

export const Container = styled.div``

export const CustomStyles = {
  headRow: {
    style: {
      border: 'none',
      backgroundColor: '#a8a8b3'
    }
  },
  headCells: {
    style: {
      border: 'none',
      color: '#fff',
      fontSize: '16px'
    }
  },
  rows: {
    fontSize: '15px',
    highlightOnHoverStyle: {
      backgroundColor: '#cccccc',
      color: '#fff',
      button: {
        backgroundColor: '#fff',
        svg: {
          fill: '#cccccc'
        }
      }
    }
  },
  pagination: {
    style: {
      ontSize: '16px',
      border: 'none'
    }
  }
}
