import styled from 'styled-components'

export const Container = styled.div`
  grid-area: footer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 2em 0 2em;
  background: var(--color-gray);

  p {
    color: var(--color-green);

    strong {
      font-size: 14px;
    }
  }
`
