// Libs
import { all, takeLatest, call, put } from 'redux-saga/effects'
import { toast } from 'react-toastify'

// Api
import api from '../../../api'

// Types
import {
  USERS_LIST_R,
  USER_CREATE_R,
  USER_UPDATE_R,
  USER_DELETE_R
} from './types'

// Actions
import {
  usersListS,
  usersListE,
  userCreateS,
  userCreateE,
  userCreateV,
  userUpdateS,
  userUpdateE,
  userUpdateV,
  userDeleteS,
  userDeleteE
} from './actions'

// Utils
import { error } from '../../../utils'

export function* listUsers() {
  try {
    const res = yield call(api.users.list)

    const {
      data: {
        data,
        status: { code }
      }
    } = res

    if ([200].includes(code)) {
      return yield put(usersListS({ users: data }))
    }
  } catch (err) {
    if (err.response) {
      const {
        data: {
          status: { code, message }
        }
      } = err.response

      if ([400].includes(code)) {
        toast.error(`Erro na listagem. ${message}`, error)
        return yield put(usersListE())
      }

      if ([500].includes(code)) {
        toast.error('Erro na listagem. tente novamente mais tarde!', error)
        return yield put(usersListE())
      }
    } else {
      toast.error('Erro na listagem. tente novamente mais tarde!', error)
      return yield put(usersListE())
    }
  }
}

export function* createUser({ payload }) {
  try {
    const {
      name,
      email,
      cpf,
      cep,
      password,
      address,
      city,
      state,
      country
    } = payload
    const res = yield call(api.users.create, {
      name,
      email,
      cpf,
      cep,
      password,
      address,
      city,
      state,
      country
    })

    const {
      data: {
        data: userData,
        status: { code }
      }
    } = res
    if ([201].includes(code)) {
      toast.success(`Usuario Criado com Sucesso ${userData.name}`)
      yield put(userCreateS())
      return yield call(listUsers)
    }
  } catch (err) {
    if (err.response) {
      const {
        data: {
          data: userData,
          status: { code, message }
        }
      } = err.response
      if ([400].includes(code)) {
        if (userData) {
          const validations = userData.map(e => e)
          toast.error('Erro ao criar um Usuario. Verifique seus dados', error)
          return yield put(userCreateV({ errors: validations }))
        }
        toast.error(`Erro ao criar um Usuario. ${message}`, error)
        return yield put(userCreateE())
      }

      if ([500].includes(code)) {
        toast.error(
          'Erro ao criar um Usuario. tente novamente mais tarde!',
          error
        )
        return yield put(userCreateE())
      }
    } else {
      toast.error(
        'Erro ao criar um Usuario. tente novamente mais tarde!',
        error
      )
      return yield put(userCreateE())
    }
  }
}
export function* updateUser({ payload }) {
  try {
    const { id, name, email, cpf, cep, address, city, state, country } = payload
    const res = yield call(api.users.update, {
      id,
      name,
      email,
      cpf,
      cep,
      address,
      city,
      state,
      country
    })

    const {
      data: {
        data: userData,
        status: { code }
      }
    } = res
    if ([200].includes(code)) {
      toast.success(`Usuario ${userData.name} Atualizado com Sucesso!`)
      yield put(userUpdateS())
      return yield call(listUsers)
    }
  } catch (err) {
    if (err.response) {
      const {
        data: {
          data: userData,
          status: { code, message }
        }
      } = err.response
      if ([400].includes(code)) {
        if (userData) {
          const validations = userData.map(e => e)
          toast.error(
            'Erro ao atualizar um Usuario. Verifique seus dados',
            error
          )
          return yield put(userUpdateV({ errors: validations }))
        }
        toast.error(`Erro ao atualizar um Usuario. ${message}`, error)
        return yield put(userUpdateE())
      }

      if ([500].includes(code)) {
        toast.error(
          'Erro ao atualizar um Usuario. tente novamente mais tarde!',
          error
        )
        return yield put(userUpdateE())
      }
    } else {
      toast.error(
        'Erro ao atualizar um Usuario. tente novamente mais tarde!',
        error
      )
      return yield put(userUpdateE())
    }
  }
}

export function* deleteUser({ payload }) {
  try {
    const { id } = payload
    const res = yield call(api.users.deleteU, { id })

    const {
      data: {
        status: { code }
      }
    } = res

    if ([203].includes(code)) {
      toast.success('Usuario Deletado com Sucesso')
      return yield put(userDeleteS({ id }))
    }
  } catch (err) {
    if (err.response) {
      const {
        data: {
          status: { code, message }
        }
      } = err.response
      if ([400].includes(code)) {
        toast.error(`Erro ao deletar um Usuario. ${message}`, error)
        return yield put(userDeleteE())
      }

      if ([500].includes(code)) {
        toast.error(
          'Erro ao deletar um Usuario. tente novamente mais tarde!',
          error
        )
        return yield put(userDeleteE())
      }
    } else {
      toast.error(
        'Erro ao deletar um Usuario. tente novamente mais tarde!',
        error
      )
      return yield put(userDeleteE())
    }
  }
}
export default all([
  takeLatest(USERS_LIST_R, listUsers),
  takeLatest(USER_CREATE_R, createUser),
  takeLatest(USER_UPDATE_R, updateUser),
  takeLatest(USER_DELETE_R, deleteUser)
])
