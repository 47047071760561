// List
const DASHBOARD_LIST_R = '@dashboard/DASHBOARD_LIST_R'
const DASHBOARD_LIST_S = '@dashboard/DASHBOARD_LIST_S'
const DASHBOARD_LIST_E = '@dashboard/DASHBOARD_LIST_E'


export {
  DASHBOARD_LIST_R,
  DASHBOARD_LIST_S,
  DASHBOARD_LIST_E
}
