// Libs
import { all, takeLatest, call, put } from 'redux-saga/effects'
import { toast } from 'react-toastify'

// Api
import api from '../../../api'

// Types
import { DASHBOARD_LIST_R } from './types'

// Actions
import { dashboardListS, dashboardListE } from './actions'

// Utils
import { error } from '../../../utils'

export function* listStats() {
  try {
    const res = yield call(api.dashboard.listStats)

    const {
      data: {
        data,
        status: { code }
      }
    } = res

    if ([200].includes(code)) return yield put(dashboardListS({ stats: data }))
  } catch (err) {
    if (err.response) {
      const {
        data: {
          status: { code, message }
        }
      } = err.response

      if ([400].includes(code)) {
        toast.error(`Erro ao buscar os Status. ${message}`, error)
        return yield put(dashboardListE())
      }

      if ([500].includes(code)) {
        toast.error(
          'Erro ao buscar os Status. tente novamente mais tarde!',
          error
        )
        return yield put(dashboardListE())
      }
    } else {
      toast.error(
        'Erro ao buscar os Status. tente novamente mais tarde!',
        error
      )
      return yield put(dashboardListE())
    }
  }
}

export default all([takeLatest(DASHBOARD_LIST_R, listStats)])
