import React, { useEffect, useState, useRef, useCallback } from 'react'

// Libs
import { Form } from '@unform/web'
import { useDispatch, useSelector } from 'react-redux'

// Api
import { signInR } from '../../store/modules'

// Components
import { Input, Button } from '../../components'

// Styles
import { Container, AnimatedContainer, Group, Content } from './styles'

// Assets
import logo from '../../assets/images/theodorojr.svg'

// Validations
import { authSchema } from '../../validations'

export const Authentication = () => {
  // Hooks
  const formRef = useRef(null)

  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [buttonDisable, setButtonDisable] = useState(true)

  // Redux
  const dispatch = useDispatch()
  const { loading, validationsErrors } = useSelector(state => state.auth)

  // Button Disable with fields empty
  useEffect(() => {
    return () =>
      email && password.length > 3
        ? setButtonDisable(false)
        : setButtonDisable(true)
  }, [email, password])

  // UseEffect Input Focus on load
  useEffect(() => {
    const inputFocus = () => {
      formRef.current.getFieldRef('email').focus()
    }
    return inputFocus()
  }, [])

  // Submit form Request
  const handleSubmit = useCallback(
    data => {
      const Auth = async () => {
        try {
          formRef.current.setErrors({})

          await authSchema.validate(data, {
            abortEarly: false
          })

          const { email, password } = data

          dispatch(signInR({ email, password }))
        } catch (err) {
          const validationErrors = {}
          if (err) {
            err.inner.forEach(error => {
              validationErrors[error.path] = error.message
            })
            formRef.current.setErrors(validationErrors)
          }
        }
      }
      return Auth()
    },
    [dispatch]
  )

  // Jsx
  return (
    <Container>
      <AnimatedContainer>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Content>
            <img src={logo} alt="Theodorojr" />
          </Content>
          <Group>
            <Input
              name="email"
              placeholder="Seu E-mail"
              icon="MdEmail"
              type="text"
              iconSize={20}
              onChange={e => setEmail(e.target.value)}
              background="#121214"
              borderColor="#0E9A59"
              iconColor="#28272c"
              borderInputColor=""
            />
            <Input
              autoComplete="off"
              name="password"
              placeholder="Sua Senha"
              icon="MdLock"
              type="password"
              iconSize={20}
              reveal
              onChange={e => setPassword(e.target.value)}
              background="#121214"
              borderColor="#0E9A59"
              iconColor="#28272c"
              borderInputColor=""
            />
          </Group>
          {validationsErrors &&
            validationsErrors.map((e, i) => <p key={i}>{e}</p>)}
          <Button type="submit" loading={loading} disabled={buttonDisable}>
            Entrar
          </Button>
        </Form>
      </AnimatedContainer>
    </Container>
  )
}
