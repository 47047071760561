import styled, { keyframes } from 'styled-components'

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  background: var(--color-white);
  width: 100%;
  height: 100vh;
  padding: 30px 20px;
  margin-top: 2em;

  @media only screen and (min-width: 1025px) {
    padding: 80px 60px;
  }
`

export const Content = styled.div`
  animation: ${appearFromRight} 0.5s ease;
`
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  h1 {
    font-size: 24px;
    color: #121214;
    text-transform: uppercase;
  }

  @media only screen and (min-width: 1025px) {
    flex-direction: row;
    align-items: baseline;
  }
`

export const Card = styled.div`
  text-align: center;
  padding: 2rem;
  div.recharts-wrapper {
    width: 100% !important;
    height: 100% !important;

    svg {
      width: 250px !important;
      height: 250px !important;
      top: -10%;
      left: -4%;
      position: relative;
    }

    div.recharts-tooltip-wrapper.recharts-tooltip-wrapper-right.recharts-tooltip-wrapper-bottom {
      top: -30%!important;
    }
  }
`
