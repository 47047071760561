import {
  USERS_LIST_R,
  USERS_LIST_S,
  USERS_LIST_E,
  USER_CREATE_R,
  USER_CREATE_S,
  USER_CREATE_E,
  USER_CREATE_V,
  USER_UPDATE_R,
  USER_UPDATE_S,
  USER_UPDATE_E,
  USER_UPDATE_V,
  USER_DELETE_R,
  USER_DELETE_S,
  USER_DELETE_E
} from './types'

export const usersListR = payload => ({
  type: USERS_LIST_R,
  payload
})

export const usersListS = payload => ({
  type: USERS_LIST_S,
  payload
})

export const usersListE = () => ({
  type: USERS_LIST_E
})

export const userCreateR = payload => ({
  type: USER_CREATE_R,
  payload
})

export const userCreateS = payload => ({
  type: USER_CREATE_S,
  payload
})

export const userCreateE = () => ({
  type: USER_CREATE_E
})

export const userCreateV = payload => ({
  type: USER_CREATE_V,
  payload
})

export const userUpdateR = payload => ({
  type: USER_UPDATE_R,
  payload
})

export const userUpdateS = () => ({
  type: USER_UPDATE_S
})

export const userUpdateE = () => ({
  type: USER_UPDATE_E
})

export const userUpdateV = payload => ({
  type: USER_UPDATE_V,
  payload
})

export const userDeleteR = payload => ({
  type: USER_DELETE_R,
  payload
})

export const userDeleteS = payload => ({
  type: USER_DELETE_S,
  payload
})

export const userDeleteE = () => ({
  type: USER_DELETE_E
})
