import styled from 'styled-components'

export const Container = styled.div`
  grid-area: header;
  display: flex;
  justify-content: space-between;
  background: var(--color-white);
  box-shadow: 0px -6px 10px 0px rgba(0, 0, 0, 0.53);
  z-index: 1;
`

export const Menu = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.5s ease-in-out;
  padding: 2rem;
  cursor: pointer;

  ${({ toggleState }) => (toggleState ? 'transform: translateX(-50px);' : '')}

  @media only screen and (min-width: 1025px) {
    display: none;
  }
  :hover {
    background: var(--color-background);
  }

  > svg {
    fill: var(--color-green);
  }
`

export const Statistics = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  padding: 2rem;

  > div {
    padding: 1rem;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;

    p {
      display: flex;
      flex-direction: column;
      color: #a8a8b3;
      strong {
        color: var(--color-green);
        font-size: 20px;
      }
    }
  }

  @media only screen and (max-width: 425px) {
    padding: 0px;
    > div {
      padding: 0.5rem;
    }
  }
  @media only screen and (max-width: 375px) {
    display: none;
  }
`
export const Divider = styled.hr`
  height: 30px;
  border: 1px solid #a8a8b3;
`
export const Avatar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2rem;

  > p {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    text-align: end;
    strong {
      color: #d34242;
      font-weight: bold;
      cursor: pointer;
    }
  }

  > div {
    & img {
      border-radius: 50%;
      width: 40px;
    }
  }

  @media only screen and (min-width: 425px) {
    padding: 0px;
  }
`
