import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  background: var(--color-white);
  width: 100%;
  height: 100vh;
  padding: 30px 20px;
  margin-top: 2em;

  h1 {
    font-size: 24px;
    color: #121214;
    text-transform: uppercase;
  }

  @media only screen and (min-width: 1025px) {
    padding: 80px 60px;
  }
`
