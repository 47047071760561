// Libs
import React, { useMemo, useRef } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import PropTypes, { arrayOf } from 'prop-types'
import { MdClose } from 'react-icons/md'

// Styles
import {
  Container,
  MenuClose,
  LogoContainer,
  PrimaryItem,
  OuterItem,
  OuterContainer,
  OuterIcon
} from './styles'

// Assets
import logo from '../../assets/images/theodorojr.svg'

export const Sidebar = ({ toggle, toggleState, routes }) => {
  // Hooks
  const location = useLocation()
  const history = useHistory()
  const modalRef = useRef(null)

  const RoutesMemo = useMemo(() => {
    if (routes) {
      return routes.map(({ label, children }, i) => (
        <ul key={i}>
          <PrimaryItem>
            <p>{label}</p>
          </PrimaryItem>
          {children &&
            children.map(({ icon, route, label, Modal }, i) => {
              if (Modal) {
                return (
                  <OuterItem
                    key={i}
                    onClick={() => {
                      toggle()
                      if (!modalRef.current.isOpen) {
                        return modalRef.current.toggleModal()
                      }
                    }}
                  >
                    {Modal && <Modal modalRef={modalRef} />}
                    <OuterContainer>
                      <OuterIcon>{icon}</OuterIcon>
                      <p>{label}</p>
                    </OuterContainer>
                  </OuterItem>
                )
              } else {
                return (
                  <OuterItem
                    active={route === location.pathname}
                    key={i}
                    onClick={() => {
                      toggle()
                      return history.push(route)
                    }}
                  >
                    <OuterContainer active={route === location.pathname}>
                      <OuterIcon>{icon}</OuterIcon>
                      <p>{label}</p>
                    </OuterContainer>
                  </OuterItem>
                )
              }
            })}
        </ul>
      ))
    }
  }, [history, location.pathname, routes, toggle])
  return (
    <Container toggleState={toggleState}>
      <div>
        <LogoContainer>
          <img src={logo} alt="Theodorojr" />
          <MenuClose
            toggleState
            onClick={() => {
              toggle()
            }}
          >
            <MdClose size={24} />
          </MenuClose>
        </LogoContainer>
        {RoutesMemo}
      </div>
    </Container>
  )
}

Sidebar.defaultProps = {
  mapping: []
}

Sidebar.propTypes = {
  mapping: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      children: arrayOf(
        PropTypes.shape({
          icon: PropTypes.element,
          label: PropTypes.string,
          route: PropTypes.string
        })
      )
    })
  )
}
