import styled, { keyframes } from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`

export const AnimatedContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  animation: ${appearFromRight} 0.5s ease;

  form {
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 480px;
    background: var(--color-gray);
    border-radius: 5px;
    padding: 3rem;

    p {
      font-size: 14px;
      color: var(--color-white);
      margin-top: 5px;
    }
  }
`

export const Group = styled.section`
  display: grid;
  grid-auto-flow: row;
  gap: 0.5rem;

  input {
    svg {
      font-size: 18px;
    }
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  width: 100%;
  justify-content: center;
  text-align: center;
  padding: 1rem;
  img {
    padding-bottom: 1rem;
  }
`
