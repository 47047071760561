import React from 'react'

// Libs
import { Form } from '@unform/web'
import { MdFindReplace, MdPersonAdd } from 'react-icons/md'

// Components
import { Select, Input } from '../../../../../components'

// Responsive
import { Row, Column } from '../../../../../styles'

// Styles
import { Container } from './styles'

export const Filter = ({
  selectData,
  onSelect,
  onFilter,
  filter,
  onClear,
  onModal
}) => {
  return (
    <Container>
      <Form>
        <Row>
          <Column xs="12" sm="3" md="3" lg="3">
            <Select
              name="searchOptions"
              defaultValue={selectData[0]}
              options={selectData}
              onChange={onSelect}
            />
          </Column>
          <Column xs="12" sm="5" md="5" lg="5">
            <Input
              name="search"
              type="text"
              placeholder="Sua Pesquisa..."
              icon="MdSearch"
              value={filter}
              onChange={onFilter}
              background="#2d9a68"
              borderColor="#202024"
              iconColor="#fff"
              borderInputColor="#2d9a68"
            />
          </Column>
          <Column xs="12" sm="2" md="2" lg="2">
            <button type="button" onClick={onClear}>
              <MdFindReplace size={24} />
            </button>
          </Column>
          <Column xs="12" sm="2" md="2" lg="2">
            <button type="button" onClick={() => onModal(null)}>
              <MdPersonAdd size={24} />
            </button>
          </Column>
        </Row>
      </Form>
    </Container>
  )
}
