import { produce } from 'immer'

import {
  COURSES_LIST_R,
  COURSES_LIST_S,
  COURSES_LIST_E,
  COURSE_CREATE_R,
  COURSE_CREATE_S,
  COURSE_CREATE_E,
  COURSE_CREATE_V,
  COURSE_UPDATE_R,
  COURSE_UPDATE_S,
  COURSE_UPDATE_E,
  COURSE_UPDATE_V,
  COURSE_DELETE_R,
  COURSE_DELETE_S,
  COURSE_DELETE_E,
  COURSE_ACTIVE_R,
  COURSE_ACTIVE_S,
  COURSE_ACTIVE_E,
  COURSE_DESACTIVE_R,
  COURSE_DESACTIVE_S,
  COURSE_DESACTIVE_E
} from './types'

const initialState = {
  courses: null,
  loading: false,
  validationsErrors: null
}

export const courses = (state = initialState, { type, payload }) => {
  return produce(state, draft => {
    switch (type) {
      case COURSES_LIST_R: {
        draft.loading = true
        break
      }
      case COURSES_LIST_S: {
        draft.loading = false
        draft.courses = payload.courses
        break
      }
      case COURSES_LIST_E: {
        draft.loading = false
        break
      }
      case COURSE_CREATE_R: {
        draft.loading = true
        draft.validationsErrors = null
        break
      }
      case COURSE_CREATE_S: {
        draft.loading = false
        draft.validationsErrors = null
        break
      }
      case COURSE_CREATE_E: {
        draft.loading = false
        break
      }
      case COURSE_CREATE_V: {
        draft.loading = false
        draft.validationsErrors = payload.errors
        break
      }
      case COURSE_UPDATE_R: {
        draft.loading = true
        draft.validationsErrors = null
        break
      }
      case COURSE_UPDATE_S: {
        draft.loading = false
        draft.validationsErrors = null
        break
      }
      case COURSE_UPDATE_E: {
        draft.loading = false
        break
      }
      case COURSE_UPDATE_V: {
        draft.loading = false
        draft.validationsErrors = payload.errors
        break
      }
      case COURSE_DELETE_R: {
        draft.loading = true
        draft.validationsErrors = null
        break
      }
      case COURSE_DELETE_S: {
        draft.loading = false
        draft.validationsErrors = null
        draft.courses = state.courses.filter(e => e.id !== payload.id)
        break
      }
      case COURSE_DELETE_E: {
        draft.loading = false
        break
      }
      case COURSE_ACTIVE_R: {
        draft.loading = true
        break
      }
      case COURSE_ACTIVE_S: {
        draft.loading = false
        break
      }
      case COURSE_ACTIVE_E: {
        draft.loading = false
        break
      }
      case COURSE_DESACTIVE_R: {
        draft.loading = true
        break
      }
      case COURSE_DESACTIVE_S: {
        draft.loading = false
        break
      }
      case COURSE_DESACTIVE_E: {
        draft.loading = false
        break
      }
      default:
        return state
    }
  })
}
