import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #a8a8b3;
  padding: 20px;
`

export const Tables = styled.div`
  display: flex;
  width: 100%;
  padding: 20px;
  header {
    display: flex;
    align-items: end;
    max-width: 280px;
    width: 180px;
    h1 {
      font-size: 16px;
      color: rgb(255, 255, 255);
    }
    p {
      font-size: 16px;
      color: rgb(255, 255, 255);
      padding-left: 20px;
      margin-left: 30px;
      border-left: 1px solid rgb(255, 255, 255);
      word-break: break-word;
      max-width: 280px;
    }
  }
  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px 10px;
    thead {
      tr {
        th {
          color: #fff;
          font-size: 18px;
          font-weight: 500;
          text-align: left;
          padding-left: 15px;
          padding-bottom: 10px;
        }
      }
    }
    tbody {
      tr {
        background: #202024c7;
        border-radius: 5px;
        transition: background 0.2s ease 0s;

        td {
          color: #fff;
          padding: 10px 15px;
          font-size: 16px;

          div {
            display: flex;
            align-items: center;
          }

          &:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }
        }
      }
    }
  }
`
