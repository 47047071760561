import { produce } from 'immer'

import {
  BANNER_LIST_R,
  BANNER_LIST_S,
  BANNER_LIST_E,
  BANNER_CREATE_OR_UPDATE_R,
  BANNER_CREATE_OR_UPDATE_E,
  BANNER_CREATE_OR_UPDATE_S,
  BANNER_CREATE_OR_UPDATE_V
} from './types'

const initialState = {
  banners: null,
  loading: false,
  validationsErrors: null
}

export const banners = (state = initialState, { type, payload }) => {
  return produce(state, draft => {
    switch (type) {
      case BANNER_LIST_R: {
        draft.loading = true
        draft.validationsErrors = null
        break
      }
      case BANNER_LIST_S: {
        draft.loading = false
        draft.validationsErrors = null
        draft.banners = payload.banners
        break
      }
      case BANNER_LIST_E: {
        draft.loading = false
        draft.validationsErrors = null
        break
      }
      case BANNER_CREATE_OR_UPDATE_R: {
        draft.loading = true
        draft.validationsErrors = null
        break
      }
      case BANNER_CREATE_OR_UPDATE_S: {
        draft.loading = false
        draft.validationsErrors = null
        break
      }
      case BANNER_CREATE_OR_UPDATE_E: {
        draft.loading = false
        draft.validationsErrors = null
        break
      }
      case BANNER_CREATE_OR_UPDATE_V: {
        draft.loading = false
        draft.validationsErrors = payload.errors
        break
      }
      default:
        return state
    }
  })
}
