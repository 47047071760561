import React, { useState, forwardRef, useImperativeHandle } from 'react'

// Libs
import { MdClose } from 'react-icons/md'

// Styles
import { Container, CustomModal } from './styles'

export const Modal = forwardRef(({ children }, ref) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleModal = () => setIsOpen(!isOpen)

  useImperativeHandle(ref, () => ({toggleModal, isOpen}))

  return (
    <Container>
      <CustomModal
        isOpen={isOpen}
        onEscapeKeydown={toggleModal}
      >
        {children}
        <button className="button-close" onClick={toggleModal}><MdClose size={24}/></button>
      </CustomModal>
    </Container>
  )
})

