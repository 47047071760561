import * as Yup from 'yup'

export const authSchema = Yup.object().shape({
  email: Yup.string()
    .email('Insira um email válido')
    .required('O email é obrigatório'),
  password: Yup.string()
    .min(6, 'No mínimo, 6 caracteres')
    .required('A senha é obrigatória')
})
