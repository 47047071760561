// Libs
import React, { useCallback } from 'react'
import { MdModeEdit, MdDeleteSweep } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'

// Redux
import { courseDeleteR } from '../../../../../store/modules'

// Styles
import { Container, Button } from './styles'

export const Menu = ({ row, onModal }) => {
  // Redux
  const dispatch = useDispatch()

  const deleteCourse = useCallback(() => {
    Swal.fire({
      icon: 'warning',
      title: 'Quer mesmo deletar esse Curso?',
      showCancelButton: true,
      confirmButtonColor: '#2d9a68',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      cancelButtonColor: '#121214'
    }).then(result => {
      if (result.isConfirmed) return dispatch(courseDeleteR({ id: row.id }))
    })
  }, [row, dispatch])

  return (
    <Container>
      <Button onClick={() => onModal(row)}>
        <MdModeEdit size={24} />
      </Button>
      <Button onClick={deleteCourse}>
        <MdDeleteSweep size={24} />
      </Button>
    </Container>
  )
}
