import { combineReducers } from 'redux'

import { auth } from './auth'
import { users } from './users'
import { courses } from './courses'
import { lessons } from './lessons'
import { utils } from './utils'
import { dashboard } from './dashboard'
import { banners } from './banners'

export default combineReducers({
  auth,
  users,
  courses,
  lessons,
  utils,
  dashboard,
  banners
})
