// Libs
import React, { useRef, useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form } from '@unform/web'

// Components
import { Input, ButtonGroups, Button } from '../../../../../../components'

// Redux
import { lessonCreateR } from '../../../../../../store/modules'

// Responsive
import { Row, Column } from '../../../../../../styles'

// Styles
import { Container, Content } from './styles'

// Validations
import { lessonsSchema } from '../../../../../../validations'

export const ModalCreate = () => {
  // Hooks
  const formRef = useRef(null)

  // States
  const [active, setActive] = useState('material')
  const [activeVideo, setActiveVideo] = useState('vimeo')

  // Redux
  const dispatch = useDispatch()
  const { loading, validationsErrors } = useSelector(state => state.lessons)

  // Mode Switch
  const handleActiveMode = useCallback(type => {
    formRef.current.setErrors({})
    setActive(type)
  }, [])

  // Mode Switch
  const handleActiveModeVideo = useCallback(type => {
    formRef.current.setErrors({})
    setActiveVideo(type)
  }, [])

  // Submit form Request
  const handleSubmit = useCallback(
    async submitData => {
      try {
        formRef.current.setErrors({})

        await lessonsSchema.validate(submitData, {
          abortEarly: false
        })

        const { title, order, slug, resource, attachments } = submitData

        const lesson = {
          type:
            active.toLowerCase() === 'audio' ? 'sound' : active.toLowerCase(),
          content: {
            title,
            slug,
            order: Number(order),
            resource
          }
        }
        if (active.toLowerCase() === 'audio') lesson.content.platform = 'sound'
        if (active.toLowerCase() === 'video')
          lesson.content.platform = activeVideo.toLowerCase()
        if (attachments) lesson.content.attachments = attachments

        dispatch(lessonCreateR(lesson))
        !validationsErrors && formRef.current.reset()
      } catch (err) {
        const validationErrors = {}
        if (err) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message
          })
          formRef.current.setErrors(validationErrors)
        }
      }
    },
    [active, activeVideo, dispatch, validationsErrors]
  )

  return (
    <Container>
      <Content>
        <h5>
          Criar uma nova <strong>Aula</strong>
        </h5>
        <p>Selecione o tipo da Aula:</p>
        <ButtonGroups
          active={active}
          onActive={type => handleActiveMode(type)}
          types={['material', 'video', 'audio']}
        />
      </Content>
      {active === 'video' && (
        <Content>
          <p>Selecione o tipo do Conteudo:</p>
          <ButtonGroups
            active={activeVideo}
            onActive={type => handleActiveModeVideo(type)}
            types={['youtube', 'vimeo']}
          />
        </Content>
      )}
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Row>
          <Column className="padding-top" xs="12" sm="5" md="5" lg="6">
            <Input
              name="title"
              type="text"
              label="Título"
              placeholder="Título da Aula..."
            />
          </Column>
          <Column className="padding-top" xs="12" sm="5" md="5" lg="4">
            <Input
              name="slug"
              type="text"
              label="Slug"
              placeholder="Slug da Aula..."
            />
          </Column>
          <Column className="padding-top" xs="12" sm="5" md="5" lg="2">
            <Input
              name="order"
              type="number"
              label="Ordem"
              placeholder="Ordem"
            />
          </Column>

          <Column xs="12" sm="5" md="5" lg="12">
            <Input
              name="resource"
              type="text"
              label="Conteúdo"
              placeholder="Url do Conteúdo da Aula..."
            />
          </Column>
          <Column xs="12" sm="5" md="5" lg="12">
            <Input
              name="attachments"
              type="text"
              label="Anexos"
              placeholder="Urls dos Anexos,Anexos"
            />
          </Column>
          <Column xs="12" sm="5" md="5" lg="6" className="padding-top">
            {validationsErrors &&
              validationsErrors.map((e, i) => <p key={i}>{e}</p>)}
          </Column>
          <Column xs="12" sm="5" md="5" lg="12">
            <Button loading={loading} type="submit">
              Criar
            </Button>
          </Column>
        </Row>
      </Form>
    </Container>
  )
}
