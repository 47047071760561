import {
  COURSES_LIST_R,
  COURSES_LIST_S,
  COURSES_LIST_E,
  COURSE_CREATE_R,
  COURSE_CREATE_S,
  COURSE_CREATE_E,
  COURSE_CREATE_V,
  COURSE_UPDATE_R,
  COURSE_UPDATE_S,
  COURSE_UPDATE_E,
  COURSE_UPDATE_V,
  COURSE_DELETE_R,
  COURSE_DELETE_S,
  COURSE_DELETE_E,
  COURSE_ACTIVE_R,
  COURSE_ACTIVE_S,
  COURSE_ACTIVE_E,
  COURSE_DESACTIVE_R,
  COURSE_DESACTIVE_S,
  COURSE_DESACTIVE_E
} from './types'

// List
export const coursesListR = payload => ({
  type: COURSES_LIST_R,
  payload
})
export const coursesListS = payload => ({
  type: COURSES_LIST_S,
  payload
})
export const coursesListE = () => ({
  type: COURSES_LIST_E
})
// Create
export const courseCreateR = payload => ({
  type: COURSE_CREATE_R,
  payload
})
export const courseCreateS = payload => ({
  type: COURSE_CREATE_S,
  payload
})
export const courseCreateE = () => ({
  type: COURSE_CREATE_E
})
export const courseCreateV = payload => ({
  type: COURSE_CREATE_V,
  payload
})
// Update
export const courseUpdateR = payload => ({
  type: COURSE_UPDATE_R,
  payload
})
export const courseUpdateS = () => ({
  type: COURSE_UPDATE_S
})
export const courseUpdateE = () => ({
  type: COURSE_UPDATE_E
})
export const courseUpdateV = payload => ({
  type: COURSE_UPDATE_V,
  payload
})
// Delete
export const courseDeleteR = payload => ({
  type: COURSE_DELETE_R,
  payload
})

export const courseDeleteS = payload => ({
  type: COURSE_DELETE_S,
  payload
})

export const courseDeleteE = () => ({
  type: COURSE_DELETE_E
})

// Active/Desactive
export const courseActiveR = payload => ({
  type: COURSE_ACTIVE_R,
  payload
})

export const courseActiveS = payload => ({
  type: COURSE_ACTIVE_S,
  payload
})

export const courseActiveE = () => ({
  type: COURSE_ACTIVE_E
})

export const courseDesactiveR = payload => ({
  type: COURSE_DESACTIVE_R,
  payload
})
export const courseDesactiveS = payload => ({
  type: COURSE_DESACTIVE_S,
  payload
})
export const courseDesactiveE = () => ({
  type: COURSE_DESACTIVE_E
})
