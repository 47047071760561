import styled from 'styled-components'

export const Container = styled.div`
  padding-top: 2em;
  
  form {
    div {
      gap: 1em;
    }
  }
  @media only screen and (min-width: 768px) {
    form {
      div {
        gap: 0;
      }
    }
  }
`
