//Libs
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Redux
import { usersListR } from '../../store/modules'

// Components
import { UserTable } from './content'

// Styles
import { Container } from './styles'

export const Users = () => {
  const dispatch = useDispatch()
  const { users, loading } = useSelector(state => state.users)

  useEffect(() => {
    if (!users) dispatch(usersListR())
  }, [users, dispatch])

  const Table = useMemo(() => {
    if (users) return <UserTable data={users} loading={loading} />
  }, [users, loading])

  // Users
  return <Container>{Table}</Container>
}
