import React from 'react'

// Libs
import ReactTable from 'react-data-table-component'
import PropTypes from 'prop-types'

// Styles
import { Container, CustomStyles } from './styles'

// Table
export const Table = ({ columns, data, ...rest }) => {
  return (
    <Container>
      <ReactTable
        columns={columns}
        data={data}
        pagination
        paginationComponentOptions={{
          rowsPerPageText: 'Filas por página',
          rangeSeparatorText: 'de',
          selectAllRowsItem: true,
          selectAllRowsItemText: 'Todos'
        }}
        paginationPerPage={5}
        paginationRowsPerPageOptions={[5]}
        customStyles={CustomStyles}
        highlightOnHover
        {...rest}
      />
    </Container>
  )
}

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired
}

Table.defaultProps = {
  columns: [],
  data: []
}
