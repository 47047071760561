import { produce } from 'immer'

import {
  LESSONS_LIST_R,
  LESSONS_LIST_S,
  LESSONS_LIST_E,
  LESSON_CREATE_R,
  LESSON_CREATE_S,
  LESSON_CREATE_E,
  LESSON_CREATE_V,
  LESSON_UPDATE_R,
  LESSON_UPDATE_S,
  LESSON_UPDATE_E,
  LESSON_UPDATE_V,
  LESSON_DELETE_R,
  LESSON_DELETE_S,
  LESSON_DELETE_E
} from './types'

const initialState = {
  lessons: null,
  loading: false,
  validationsErrors: null
}

export const lessons = (state = initialState, { type, payload }) => {
  return produce(state, draft => {
    switch (type) {
      case LESSONS_LIST_R: {
        draft.loading = true
        break
      }
      case LESSONS_LIST_S: {
        draft.loading = false
        draft.lessons = payload.lessons
        break
      }
      case LESSONS_LIST_E: {
        draft.loading = false
        break
      }
      case LESSON_CREATE_R: {
        draft.loading = true
        draft.validationsErrors = null
        break
      }
      case LESSON_CREATE_S: {
        draft.loading = false
        draft.validationsErrors = null
        break
      }
      case LESSON_CREATE_E: {
        draft.loading = false
        break
      }
      case LESSON_CREATE_V: {
        draft.loading = false
        draft.validationsErrors = payload.errors
        break
      }
      case LESSON_UPDATE_R: {
        draft.loading = true
        draft.validationsErrors = null
        break
      }
      case LESSON_UPDATE_S: {
        draft.loading = false
        draft.validationsErrors = null
        break
      }
      case LESSON_UPDATE_E: {
        draft.loading = false
        break
      }
      case LESSON_UPDATE_V: {
        draft.loading = false
        draft.validationsErrors = payload.errors
        break
      }
      case LESSON_DELETE_R: {
        draft.loading = true
        draft.validationsErrors = null
        break
      }
      case LESSON_DELETE_S: {
        draft.loading = false
        draft.validationsErrors = null
        draft.lessons = state.lessons.filter(e => e.id !== payload.id)
        break
      }
      case LESSON_DELETE_E: {
        draft.loading = false
        break
      }
      default:
        return state
    }
  })
}
