import { produce } from 'immer'

import { DASHBOARD_LIST_R, DASHBOARD_LIST_S, DASHBOARD_LIST_E } from './types'

const initialState = {
  stats: null,
  loading: false
}

export const dashboard = (state = initialState, { type, payload }) => {
  return produce(state, draft => {
    switch (type) {
      case DASHBOARD_LIST_R: {
        draft.loading = true
        break
      }
      case DASHBOARD_LIST_S: {
        draft.loading = false
        draft.stats = payload.stats
        break
      }
      case DASHBOARD_LIST_E: {
        draft.loading = false
        break
      }
      default:
        return state
    }
  })
}
