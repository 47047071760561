// Libs
import { all, takeLatest, call, put } from 'redux-saga/effects'
import { toast } from 'react-toastify'

// Api
import api from '../../../api'

// Types
import { BANNER_LIST_R, BANNER_CREATE_OR_UPDATE_R } from './types'

// Actions
import {
  bannerListS,
  bannerListE,
  bannerCreateOrUpdateS,
  bannerCreateOrUpdateE,
  bannerCreateOrUpdateV
} from './actions'

// Utils
import { error } from '../../../utils'

export function* listBanners() {
  try {
    const res = yield call(api.banners.list)

    const {
      data: {
        data,
        status: { code }
      }
    } = res
    if ([200].includes(code)) return yield put(bannerListS({ banners: data }))
  } catch (err) {
    if (err.response) {
      const {
        data: {
          status: { code, message }
        }
      } = err.response

      if ([400].includes(code)) {
        toast.error(`Erro ao buscar os Banners. ${message}`, error)
        return yield put(bannerListE())
      }

      if ([500].includes(code)) {
        toast.error(
          'Erro ao buscar os Banners. tente novamente mais tarde!',
          error
        )
        return yield put(bannerListE())
      }
    } else {
      toast.error(
        'Erro ao buscar os Banners. tente novamente mais tarde!',
        error
      )
      return yield put(bannerListE())
    }
  }
}

export function* createOrUpdate({ payload }) {
  try {
    const { authenticate, courses, certificate } = payload

    const res = yield call(api.banners.createUpdate, {
      authenticate,
      courses,
      certificate
    })

    const {
      data: {
        status: { code }
      }
    } = res
    if ([201].includes(code)) {
      toast.success(`Banners Criado com Sucesso`)
      yield put(bannerCreateOrUpdateS())
      return yield call(listBanners)
    }
    if ([200].includes(code)) {
      toast.success(`Banners Atualizado com Sucesso`)
      yield put(bannerCreateOrUpdateS())
      return yield call(listBanners)
    }
  } catch (err) {
    if (err.response) {
      const {
        data: {
          data: lessonData,
          status: { code, message }
        }
      } = err.response
      if ([400].includes(code)) {
        if (lessonData) {
          const validations = lessonData.map(e => e)
          toast.error('Erro ao criar os Banners. Verifique seus dados', error)
          return yield put(bannerCreateOrUpdateV({ errors: validations }))
        }
        toast.error(`Erro ao criar os Banners. ${message}`, error)
        return yield put(bannerCreateOrUpdateE())
      }

      if ([500].includes(code)) {
        toast.error(
          'Erro ao criar os Banners. tente novamente mais tarde!',
          error
        )
        return yield put(bannerCreateOrUpdateE())
      }
    } else {
      toast.error(
        'Erro ao criar os Banners. tente novamente mais tarde!',
        error
      )
      return yield put(bannerCreateOrUpdateE())
    }
  }
}

export default all([
  takeLatest(BANNER_LIST_R, listBanners),
  takeLatest(BANNER_CREATE_OR_UPDATE_R, createOrUpdate)
])
