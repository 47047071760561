// Libs
import { all, takeLatest, call, put } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import jwt from 'jsonwebtoken'

// Api
import api from '../../../api'

// Types
import { SIGN_IN_R, SIGN_OUT } from './types'

// Actions
import { signInS, signInE, signInV, signOut } from './actions'

// Utils
import { error } from '../../../utils'

// Redux
import { persistor } from '../../../store'

export function* authenticate({ payload }) {
  try {
    const { email, password } = payload

    const res = yield call(api.auth.authenticate, { email, password })

    const {
      data: {
        data,
        status: { code }
      }
    } = res
    if ([200].includes(code)) {
      const { token, user } = data
      const { role } = jwt.decode(token)
      if (role === 'master')
        return yield put(signInS({ token, name: user.name }))
      else {
        toast.warn('Você não tem permissão para acessar o admin!')
        return yield put(signInS({ token: null, name: null }))
      }
    }
  } catch (err) {
    if (err.response) {
      const {
        data: {
          data,
          status: { code, message }
        }
      } = err.response
      if ([400].includes(code)) {
        if (data) {
          const validations = data.map(e => e)
          toast.error('Erro na autenticação. Verifique seus dados', error)
          return yield put(signInV({ errors: validations }))
        }
        toast.error(`Erro na autenticação. ${message}`, error)
        return yield put(signInE())
      }

      if ([500].includes(code)) {
        toast.error('Erro na autenticação. tente novamente mais tarde!', error)
        return yield put(signInE())
      }
    } else {
      toast.error('Erro na autenticação. tente novamente mais tarde!', error)
      return yield put(signInE())
    }
  }
}

export function* clearStore() {
  persistor.pause()
  persistor.flush().then(() => persistor.purge())
  return yield put(signOut())
}

export default all([
  takeLatest(SIGN_IN_R, authenticate),
  takeLatest(SIGN_OUT, clearStore)
])
