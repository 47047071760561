import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`

  :root {
    --color-error: #d34242;
    --color-background: #121214;
    --color-background-light: #f7f5f7;
    --color-green: #2d9a68;
    --color-green-light: #0E9A53;
    --color-green-dark: #0E9A59;
    --color-gray: #202024;
    --color-gray-light: #e1e1e6;
    --color-gray-semi-dark: #a8a8b3;
    --color-gray-dark: #cccccc;
    --color-white: #fff;
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box
  }

  *:focus {
    outline: 0;
  }

  html, body, #root {
    height: 100%;
  }

  body {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    background-color: var(--color-background);
    -webkit-font-smoothing: antialiased !important;
    text-rendering: optimizelegibility !important;
  }

  body, input, button, textarea {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
  }

  button {
    cursor: pointer;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  form {
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.75em;
      background: var(--color-green-light);
      border-radius: 5px;
      border: 0px;
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      transition: background 0.2s ease 0s, color 0.2s ease 0s;

      :hover {
        background: var(--color-green-dark);
      }
    }
  }

  .Select__multi-value {
    background-color: rgb(41, 41, 46)!important;
    display: flex;
    margin: 2px;
    min-width: 0px;
    border-radius: 5px!important;
    box-sizing: border-box;
    z-index: 101;

    & .Select__multi-value__label {
      border-radius: 5px;
      color: var(--color-white);
      font-size: 16px;
      padding: 8px;
      padding-left: 10px;
    }
    & .Select__multi-value__remove {
      color: var(--color-white);
    }
  }
`
