import React, { useMemo, useState, useRef, useCallback } from 'react'

// Helpers
import { Formaters } from '../../../../helpers'

// Components
import { Modal, Table } from '../../../../components'

// SubComponents
import { Menu } from './menu'
import { Filter } from './filter'
import { UserModal } from './modal'
import { Expandable } from './expandable'

// Styles
import { Container, Content } from './styles'

export const UserTable = ({ data, loading }) => {
  // Hooks
  const modalRef = useRef(null)
  const [filter, setFilter] = useState('')
  const [editData, setEditData] = useState(null)
  const [select, setSelect] = useState({ value: 'name', label: 'Nome' })
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false)

  const filteredItems = useCallback(() => {
    if (data)
      return data.filter(
        item =>
          item[select.value] &&
          item[select.value].toLowerCase().includes(filter.toLowerCase())
      )
  }, [data, filter, select])

  // Uses
  const handleOpenModal = useCallback(data => {
    if (data) setEditData(data)
    else setEditData(null)

    modalRef.current.toggleModal()
  }, [])

  const closeModal = useCallback(() => {
    modalRef.current.toggleModal()
  }, [])

  const HeaderMemo = useMemo(() => {
    const handleClear = () => {
      if (filter) {
        setResetPaginationToggle(!resetPaginationToggle)
        setFilter('')
      }
    }

    return (
      <Filter
        selectData={[
          { value: 'name', label: 'Nome' },
          { value: 'email', label: 'Email' },
          { value: 'cpf', label: 'Cpf' }
        ]}
        onSelect={e => setSelect(e)}
        onFilter={e => setFilter(e.target.value)}
        onClear={handleClear}
        filter={filter}
        onModal={handleOpenModal}
      />
    )
  }, [filter, resetPaginationToggle, handleOpenModal])

  return (
    <Container>
      <Content>
        <h1>Usuários</h1>
        {HeaderMemo}
      </Content>
      <Table
        columns={[
          { name: 'Nome', selector: 'name', sortable: true },
          { name: 'Email', selector: 'email', sortable: true },
          {
            name: 'Cpf',
            selector: 'cpf',
            sortable: true,
            format: row => Formaters.cpf(row.cpf)
          },
          { name: 'Cep', selector: 'cep', sortable: true },
          { name: 'Endereço', selector: 'address', sortable: true },
          { name: 'Cidade', selector: 'city', sortable: true },
          { name: 'Estado', selector: 'state', sortable: true },
          { name: 'Pais', selector: 'country', sortable: true },
          {
            name: 'Data de Criação',
            selector: 'created_at',
            sortable: true,
            format: row => Formaters.date(row.created_at)
          },
          {
            name: 'Data de Atualização',
            selector: 'updated_at',
            sortable: true,
            format: row => Formaters.date(row.updated_at)
          },
          {
            cell: row => (
              <Menu row={row} onModal={data => handleOpenModal(data)} />
            )
          }
        ]}
        data={filteredItems()}
        paginationResetDefaultPage={resetPaginationToggle}
        persistTableHead
        progressPending={loading}
        expandableRows
        expandableRowsComponent={<Expandable />}
        expandOnRowClicked
      />
      <Modal ref={modalRef}>
        <UserModal modal={closeModal} data={editData} />
      </Modal>
    </Container>
  )
}
