//Libs
import React, { useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Styles
import { Container } from './styles'

// Components
import { LessonsTable } from './content'

// Redux
import { lessonsListR } from '../../store/modules'

export const Lessons = () => {
  const dispatch = useDispatch()
  const { lessons, loading } = useSelector(state => state.lessons)

  useEffect(() => {
    if (!lessons) dispatch(lessonsListR())
  }, [lessons, dispatch])

  const Table = useMemo(() => {
    if (lessons) return <LessonsTable data={lessons} loading={loading} />
  }, [lessons, loading])

  // Lessons
  return <Container>{Table}</Container>
}
