// Libs
import React, { useCallback, useState, useMemo } from 'react'

// Components
import { ButtonGroups } from '../../../../../../components'

// SubComponents
import { SelectLessons } from './select'

// Styles
import { Container, Content } from './styles'

export const ModalCreate = () => {
  // States
  const [active, setActive] = useState('selecione')

  // Mode Switch
  const handleActiveMode = useCallback(type => {
    setActive(type)
  }, [])

  const SelectMemo = useMemo(() => {
    if (active === 'selecione') {
      return <SelectLessons />
    }
  }, [active])

  return (
    <Container>
      <Content className="padding-top">
        <h5>
          Criar um novo <strong>Curso</strong>
        </h5>
        <p>Selecione o modo de criação:</p>
        <ButtonGroups
          active={active}
          onActive={type => handleActiveMode(type)}
          types={['selecione']}
        />
      </Content>
      {SelectMemo}
    </Container>
  )
}
