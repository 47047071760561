// Libs
import { all, takeLatest, call, put } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { css } from 'glamor'
import axios from 'axios'

// Types
import { GET_CEP_R } from './types'

// Actions
import { getCepS, getCepE } from './actions'

const viaCep = async cep =>
  await axios.get(`https://viacep.com.br/ws/${cep}/json/`)

export function* getCep({ payload }) {
  try {
    const { cep } = payload
    const { data, status } = yield call(viaCep, cep)
    if ([200].includes(status)) return yield put(getCepS({ cep: data }))
  } catch (err) {
    toast.error('Não foi possivel encontrar o cep. Tente novamente!', {
      className: css({
        background: '#fff !important',
        color: 'rgb(221, 90, 70) !important'
      }),
      bodyClassName: css({
        fontSize: '16px',
        fontWeight: 'bold'
      }),
      progressClassName: css({
        background: 'rgb(221, 90, 70) !important'
      })
    })
    return yield put(getCepE())
  }
}

export default all([takeLatest(GET_CEP_R, getCep)])
