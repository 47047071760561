import React, { useEffect, useMemo } from 'react'

//Libs
import { useDispatch, useSelector } from 'react-redux'

// Styles
import { Container } from './styles'

// Components
import { CoursesTable } from './content'

// Redux
import { coursesListR } from '../../store/modules'

export const Courses = () => {
  const dispatch = useDispatch()
  const { courses, loading } = useSelector(state => state.courses)

  useEffect(() => {
    if (!courses) dispatch(coursesListR())
  }, [courses, dispatch])

  const Table = useMemo(() => {
    if (courses) return <CoursesTable data={courses} loading={loading} />
  }, [courses, loading])

  // Courses
  return <Container>{Table}</Container>
}
