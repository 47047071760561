// Libs
import React, { useRef, useCallback, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form } from '@unform/web'
import { MdLoop } from 'react-icons/md'

// Components
import { Input, Button, Select } from '../../../../../../../components'

// Redux
import { lessonsListR, courseUpdateR } from '../../../../../../../store/modules'

// Responsive
import { Row, Column } from '../../../../../../../styles'

// Styles
import { Container } from './styles'

// Validations
import { courseUpdateSchema } from '../../../../../../../validations'

export const SelectLessons = ({ data }) => {
  // Hooks
  const formRef = useRef(null)

  // Redux
  const dispatch = useDispatch()
  const { lessons: courseLessons, loading: loadingLessons } = useSelector(
    state => state.lessons
  )
  const { loading: loadingCourse, validationsErrors } = useSelector(
    state => state.courses
  )

  useEffect(() => {
    if (!courseLessons) dispatch(lessonsListR())
  }, [courseLessons, dispatch])

  // Submit form Request
  const handleSubmit = useCallback(
    async submitData => {
      try {
        formRef.current.setErrors({})

        await courseUpdateSchema.validate(submitData, {
          abortEarly: false
        })

        const {
          title,
          description,
          thumbnail,
          banner,
          order,
          group
        } = submitData

        const course = {
          id: data.id,
          title,
          description,
          thumbnail,
          order: Number(order)
        }
        if (banner) course.banner = banner

        if (group) {
          const lessons = group.map(g => {
            const lesson = courseLessons.find(e => e.content.slug === g.value)
            return {
              id: lesson.id
            }
          })
          course.group = { id: data.group.id, lessons }
        }

        dispatch(courseUpdateR(course))
      } catch (err) {
        const validationErrors = {}
        if (err) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message
          })
          formRef.current.setErrors(validationErrors)
        }
      }
    },
    [courseLessons, dispatch, data.id, data.group.id]
  )

  const SelectMemo = useMemo(() => {
    if (courseLessons) {
      return (
        <Column xs="12" sm="5" md="5" lg="12">
          <p className="label-journey">Selecione as Aulas:</p>
          <Select
            name="group"
            placeholder="Selecione as Aulas..."
            drag
            options={courseLessons.map(
              e =>
                e.content && {
                  value: e.content.slug,
                  label: `${e.content.title}/${e.content.slug}`
                }
            )}
            defaultValue={data.group.lessons.map(
              e =>
                e.content && {
                  value: e.content.slug,
                  label: `${e.content.title}/${e.content.slug}`
                }
            )}
            isLoading={loadingLessons}
            isClearable={true}
            isSearchable={true}
            isMulti
          />
        </Column>
      )
    }
  }, [data, courseLessons, loadingLessons])

  useEffect(() => {
    if (data) {
      formRef.current.setData({
        title: data.title,
        order: data.order,
        description: data.description,
        thumbnail: data.thumbnail,
        banner: data.banner
      })
    }
  }, [data])

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Row>
          <Column xs="12" sm="5" md="5" lg="9">
            <Input
              name="title"
              type="text"
              label="Título"
              placeholder="Título do Curso..."
            />
          </Column>
          <Column xs="12" sm="5" md="5" lg="3">
            <Input
              name="order"
              type="number"
              label="Ordem"
              placeholder="Ordem Conteudo"
            />
          </Column>
          <Column xs="12" sm="5" md="5" lg="12">
            <Input
              name="description"
              multiline
              label="Descrição"
              placeholder="Descrição do Curso..."
            />
          </Column>
          <Column xs="12" sm="5" md="5" lg="12">
            <Input
              name="thumbnail"
              type="text"
              label="Thumbnail"
              placeholder="Thumbnail do Curso..."
            />
          </Column>
          <Column xs="12" sm="5" md="5" lg="12">
            <Input
              name="banner"
              type="text"
              label="Banner"
              placeholder="Banner do Curso..."
            />
          </Column>
          {SelectMemo}
          <Column xs="12" sm="5" md="5" lg="6" className="padding-top">
            {validationsErrors &&
              validationsErrors.map((e, i) => <p key={i}>{e}</p>)}
          </Column>
          <Column xs="12" sm="5" md="5" lg="12">
            <Button loading={loadingCourse} type="submit">
              <MdLoop size={24} /> Atualizar
            </Button>
          </Column>
        </Row>
      </Form>
    </Container>
  )
}
