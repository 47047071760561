import { produce } from 'immer'

import {
  USERS_LIST_R,
  USERS_LIST_S,
  USERS_LIST_E,
  USER_CREATE_R,
  USER_CREATE_S,
  USER_CREATE_E,
  USER_CREATE_V,
  USER_UPDATE_R,
  USER_UPDATE_S,
  USER_UPDATE_E,
  USER_UPDATE_V,
  USER_DELETE_R,
  USER_DELETE_S,
  USER_DELETE_E
} from './types'

const initialState = {
  users: null,
  loading: false,
  validationsErrors: null
}

export const users = (state = initialState, { type, payload }) => {
  return produce(state, draft => {
    switch (type) {
      case USERS_LIST_R: {
        draft.loading = true
        break
      }
      case USERS_LIST_S: {
        draft.users = payload.users
        draft.loading = false
        break
      }
      case USERS_LIST_E: {
        draft.loading = false
        break
      }
      case USER_CREATE_R: {
        draft.loading = true
        draft.validationsErrors = null
        break
      }
      case USER_CREATE_S: {
        draft.users = payload.users
        draft.loading = false
        draft.validationsErrors = null
        break
      }
      case USER_CREATE_E: {
        draft.loading = false
        break
      }
      case USER_CREATE_V: {
        draft.loading = false
        draft.validationsErrors = payload.errors
        break
      }
      case USER_UPDATE_R: {
        draft.loading = true
        draft.validationsErrors = null
        break
      }
      case USER_UPDATE_S: {
        draft.loading = false
        draft.validationsErrors = null
        break
      }
      case USER_UPDATE_E: {
        draft.loading = false
        break
      }
      case USER_UPDATE_V: {
        draft.loading = false
        draft.validationsErrors = payload.errors
        break
      }
      case USER_DELETE_R: {
        draft.loading = true
        draft.validationsErrors = null
        break
      }
      case USER_DELETE_S: {
        draft.users = state.users.filter(e => e.id !== payload.id)
        draft.loading = false
        draft.validationsErrors = null
        break
      }
      case USER_DELETE_E: {
        draft.loading = false
        break
      }
      default:
        return state
    }
  })
}
