import React from 'react'
import {
  MdDashboard,
  MdPeople,
  MdAssignment,
  MdImage,
  MdClass
} from 'react-icons/md'

import { Banners } from '../pages'

export const sideBarRoutes = [
  {
    label: 'Geral',
    children: [
      {
        icon: <MdDashboard size={24} />,
        label: 'Painel de Controle',
        route: '/dashboard'
      },
      {
        icon: <MdPeople size={24} />,
        label: 'Usuários',
        route: '/users'
      },
      {
        icon: <MdImage size={24} />,
        label: 'Banners',
        route: '/banners',
        Modal: Banners
      }
    ]
  },
  {
    label: 'Conteúdo',
    children: [
      {
        icon: <MdAssignment size={24} />,
        label: 'Cursos',
        route: '/courses'
      },
      {
        icon: <MdClass size={24} />,
        label: 'Aulas',
        route: '/lessons'
      }
    ]
  }
]
