import React, { useMemo } from 'react'

// Styles
import { Container, Tables } from './styles'

// Helpers
import { Formaters } from '../../../../../helpers'

export const Expandable = ({ data }) => {
  const Table = useMemo(() => {
    if (data) {
      return (
        <>
          {data.user_token && (
            <Tables>
              <header>
                <h1>Tokens</h1>
              </header>
              <table>
                <thead>
                  <tr>
                    <th>Permissão</th>
                    <th>Verificado</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div>{data.user_token.role}</div>
                    </td>
                    <td>
                      <div>
                        {data.user_token.user_verified
                          ? 'Verificado'
                          : 'Não Verificado'}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Tables>
          )}

          {data.history && (
            <Tables>
              <header>
                <h1>Histórico</h1>
              </header>
              <table>
                <thead>
                  <tr>
                    <th>Curso</th>
                    <th>Aula</th>
                    <th>Completo</th>
                    <th>Visto</th>
                  </tr>
                </thead>
                <tbody>
                  {data.history.progresses.map(e => (
                    <tr key={e.id}>
                      <td>
                        <div>{ e.content && e.content.lesson.group.course.title}</div>
                      </td>
                      <td>
                        <div>{e.content && e.content.title}</div>
                      </td>
                      <td>
                        <div>{e.completed ? '100%' : '0%'}</div>
                      </td>
                      <td>
                        <div>{Formaters.dateDistance(e.updated_at)}</div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Tables>
          )}

          {data.certificates.length > 0 && (
            <Tables>
              <header>
                <h1>Certificados</h1>
              </header>
              <table>
                <thead>
                  <tr>
                    <th>Curso</th>
                    <th>Total de Aulas</th>
                    <th>Total de Horas</th>
                  </tr>
                </thead>
                <tbody>
                  {data.certificates.map(e => (
                    <tr key={e.id}>
                      <td>
                        <div>{e.name}</div>
                      </td>
                      <td>
                        <div>{e.lessons}</div>
                      </td>
                      <td>
                        <div>{e.hours}</div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Tables>
          )}
        </>
      )
    }
  }, [data])
  return <Container>{Table}</Container>
}
