// List
const COURSES_LIST_R = '@courses/COURSES_LIST_R'
const COURSES_LIST_S = '@courses/COURSES_LIST_S'
const COURSES_LIST_E = '@courses/COURSES_LIST_E'
// Create
const COURSE_CREATE_R = '@courses/COURSE_CREATE_R'
const COURSE_CREATE_S = '@courses/COURSE_CREATE_S'
const COURSE_CREATE_E = '@courses/COURSE_CREATE_E'
const COURSE_CREATE_V = '@courses/COURSE_CREATE_V'
// Update
const COURSE_UPDATE_R = '@courses/COURSE_UPDATE_R'
const COURSE_UPDATE_S = '@courses/COURSE_UPDATE_S'
const COURSE_UPDATE_E = '@courses/COURSE_UPDATE_E'
const COURSE_UPDATE_V = '@courses/COURSE_UPDATE_V'
// Delete
const COURSE_DELETE_R = '@courses/COURSE_DELETE_R'
const COURSE_DELETE_S = '@courses/COURSE_DELETE_S'
const COURSE_DELETE_E = '@courses/COURSE_DELETE_E'
// Active/Desactive
const COURSE_ACTIVE_R = '@courses/COURSE_ACTIVE_R'
const COURSE_ACTIVE_S = '@courses/COURSE_ACTIVE_S'
const COURSE_ACTIVE_E = '@courses/COURSE_ACTIVE_E'
const COURSE_DESACTIVE_R = '@courses/COURSE_DESACTIVE_R'
const COURSE_DESACTIVE_S = '@courses/COURSE_DESACTIVE_S'
const COURSE_DESACTIVE_E = '@courses/COURSE_DESACTIVE_E'

export {
  COURSES_LIST_R,
  COURSES_LIST_S,
  COURSES_LIST_E,
  COURSE_CREATE_R,
  COURSE_CREATE_S,
  COURSE_CREATE_E,
  COURSE_CREATE_V,
  COURSE_UPDATE_R,
  COURSE_UPDATE_S,
  COURSE_UPDATE_E,
  COURSE_UPDATE_V,
  COURSE_DELETE_R,
  COURSE_DELETE_S,
  COURSE_DELETE_E,
  COURSE_ACTIVE_R,
  COURSE_ACTIVE_S,
  COURSE_ACTIVE_E,
  COURSE_DESACTIVE_R,
  COURSE_DESACTIVE_S,
  COURSE_DESACTIVE_E
}
