// Libs
import React, { useRef, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form } from '@unform/web'

// Components
import { Input, Button } from '../../../../../../components'

// Redux
import { userCreateR, getCepR } from '../../../../../../store/modules'

// Responsive
import { Row, Column } from '../../../../../../styles'

// Styles
import { Container, Content } from './styles'

// Validations
import { usersSchema } from '../../../../../../validations'

export const ModalCreate = () => {
  // Hooks
  const formRef = useRef(null)

  // Redux
  const dispatch = useDispatch()
  const { loading, validationsErrors } = useSelector(state => state.users)
  const { cep } = useSelector(state => state.utils)

  const setCep = useCallback(
    value => {
      value.length >= 8 && dispatch(getCepR({ cep: value }))
    },
    [dispatch]
  )

  useEffect(() => {
    const setInfos = () => {
      if (cep) {
        formRef.current.setData({
          address: cep.logradouro,
          city: cep.localidade,
          state: cep.uf,
          country: 'Brasil'
        })
      }
    }
    return setInfos()
  }, [cep])

  // Submit form Request
  const handleSubmit = useCallback(
    async submitData => {
      try {
        formRef.current.setErrors({})

        await usersSchema.validate(submitData, {
          abortEarly: false
        })

        const {
          name,
          email,
          cpf: cleanCpf,
          cep,
          password,
          city,
          state,
          address,
          country
        } = submitData
        const cpf = cleanCpf.replace('-', '').replace('.', '').replace('.', '')
        dispatch(
          userCreateR({
            name,
            email,
            cpf,
            cep,
            password,
            address,
            city,
            state,
            country
          })
        )
        !validationsErrors && formRef.current.reset()
      } catch (err) {
        const validationErrors = {}
        if (err) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message
          })
          formRef.current.setErrors(validationErrors)
        }
      }
    },
    [dispatch, validationsErrors]
  )

  return (
    <Container>
      <Content>
        <h5>
          Criar um novo <strong>Usuario</strong>
        </h5>
      </Content>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Row>
          <Column xs="12" sm="5" md="5" lg="6">
            <Input
              name="name"
              type="text"
              label="Nome"
              placeholder="Seu Nome..."
            />
          </Column>
          <Column xs="12" sm="5" md="5" lg="6">
            <Input
              name="email"
              type="text"
              label="Email"
              placeholder="Seu Email..."
            />
          </Column>
          <Column xs="12" sm="5" md="5" lg="6">
            <Input
              name="cpf"
              type="text"
              label="Cpf"
              placeholder="Seu Cpf..."
              unform={false}
              mask="999.999.999-99"
            />
          </Column>
          <Column xs="12" sm="5" md="5" lg="6">
            <Input
              name="cep"
              type="text"
              aria-label="cep"
              placeholder="Seu Cep..."
              label="Cep"
              icon="FaGlobeAmericas"
              autoComplete="off"
              onChange={e => setCep(e.target.value)}
            />
          </Column>

          <Column xs="12" sm="5" md="5" lg="6">
            <Input
              name="address"
              type="text"
              label="Endereço"
              placeholder="Seu Endereço..."
            />
          </Column>
          <Column xs="12" sm="5" md="5" lg="6">
            <Input
              name="city"
              type="text"
              label="Cidade"
              placeholder="Sua Cidade..."
            />
          </Column>
          <Column xs="12" sm="5" md="5" lg="6">
            <Input
              name="state"
              type="text"
              label="Estado"
              placeholder="Seu Estado..."
            />
          </Column>
          <Column xs="12" sm="5" md="5" lg="6">
            <Input
              name="country"
              type="text"
              label="País"
              placeholder="Seu País..."
            />
          </Column>
          <Column xs="12" sm="5" md="5" lg="6" className="padding-top">
            <Input
              autoComplete="off"
              name="password"
              label="Senha"
              placeholder="Sua Senha..."
              type="password"
              reveal
            />
          </Column>
          <Column xs="12" sm="5" md="5" lg="6" className="padding-top">
            <Input
              autoComplete="off"
              name="passwordConfirm"
              label="Confirmar sua Senha"
              placeholder="Confirme a sua Senha..."
              type="password"
              reveal
            />
          </Column>
          <Column xs="12" sm="5" md="5" lg="6" className="padding-top">
            {validationsErrors &&
              validationsErrors.map((e, i) => <p key={i}>{e}</p>)}
          </Column>
          <Column xs="12" sm="5" md="5" lg="12">
            <Button loading={loading} type="submit">
              Criar
            </Button>
          </Column>
        </Row>
      </Form>
    </Container>
  )
}
