const USERS_LIST_R = '@users/USERS_LIST_R'
const USERS_LIST_S = '@users/USERS_LIST_S'
const USERS_LIST_E = '@users/USERS_LIST_E'
const USER_CREATE_R = '@users/USER_CREATE_R'
const USER_CREATE_S = '@users/USER_CREATE_S'
const USER_CREATE_E = '@users/USER_CREATE_E'
const USER_CREATE_V = '@users/USER_CREATE_V'
const USER_UPDATE_R = '@users/USER_UPDATE_R'
const USER_UPDATE_S = '@users/USER_UPDATE_S'
const USER_UPDATE_E = '@users/USER_UPDATE_E'
const USER_UPDATE_V = '@users/USER_UPDATE_V'
const USER_DELETE_R = '@users/USER_DELETE_R'
const USER_DELETE_S = '@users/USER_DELETE_S'
const USER_DELETE_E = '@users/USER_DELETE_E'

export {
  USERS_LIST_R,
  USERS_LIST_S,
  USERS_LIST_E,
  USER_CREATE_R,
  USER_CREATE_S,
  USER_CREATE_E,
  USER_CREATE_V,
  USER_UPDATE_R,
  USER_UPDATE_S,
  USER_UPDATE_E,
  USER_UPDATE_V,
  USER_DELETE_R,
  USER_DELETE_S,
  USER_DELETE_E
}
