// Libs
import React from 'react'
// import { PieChart, Pie, Tooltip } from 'recharts'

// Styles
import { Container, Content, Wrapper } from './styles'

// // Responsive
// import { Row, Column } from '../../styles'

export const Dashboard = () => {
  // Hooks

  return (
    <Container>
      <Content>
        <Wrapper>
          <h1>Painel de Controle</h1>
        </Wrapper>
        {/* <Row>
          <Column xs="12" sm="6" md="6" lg="2">
            <Card>
              <p>Total de Alunos por curso</p>
              <PieChart width={350} height={350}>
                <Pie
                  isAnimationActive={true}
                  legendType="diamond"
                  data={data01}
                  cx={200}
                  cy={200}
                  outerRadius={80}
                  fill="#8884d8"
                  label
                />
                <Tooltip />
              </PieChart>
            </Card>
          </Column>
        </Row> */}
      </Content>
    </Container>
  )
}
