import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;

  gap: 1em;
`

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3em;
  background: var(--color-green-light);
  border-radius: 5px;
  border: 0px;
  font-size: 16px;
  font-weight: bold;
  transition: background 0.2s ease 0s, color 0.2s ease 0s;

  :hover {
    background: var(--color-green-dark);
  }

  svg {
    fill: var(--color-white);
  }
`
