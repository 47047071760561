import React from 'react'

import { Container } from './styles'

export const Footer = () => {

  return (
    <Container>
      <p>Version: 1.0.0</p>
    </Container>
  )
}
