// Libs
import React, { useRef, useCallback, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form } from '@unform/web'

// Components
import { Modal, Input, Button, Table } from '../../components'

// Redux
import { bannerListR, bannerCreateOrUpdateR } from '../../store/modules'

// Styles
import { Container } from './styles'

// Responsive
import { Row, Column } from '../../styles'

// Helpers
import { Formaters } from '../../helpers'

export const Banners = ({ modalRef }) => {
  // Hooks
  const formRef = useRef(null)

  // Redux
  const dispatch = useDispatch()
  const { banners, loading, validationsErrors } = useSelector(
    state => state.banners
  )

  useEffect(() => {
    if (!banners) dispatch(bannerListR())
  }, [banners, dispatch])

  // Submit form Request
  const handleSubmit = useCallback(
    async submitData => {
      try {
        formRef.current.setErrors({})

        const { authenticate, courses, certificate } = submitData

        dispatch(bannerCreateOrUpdateR({ authenticate, courses, certificate }))
      } catch (err) {
        const validationErrors = {}
        if (err) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message
          })
          formRef.current.setErrors(validationErrors)
        }
      }
    },
    [dispatch]
  )

  const TableMemo = useMemo(() => {
    if (banners) {
      return (
        <Row>
          <Column xs="12" sm="5" md="5" lg="12">
            <Table
              columns={[
                {
                  name: 'Banner Autenticação',
                  selector: 'authenticate',
                  cell: row =>
                    row.authenticate ? (
                      <img
                        className="banners"
                        alt="banner"
                        src={row.authenticate}
                      />
                    ) : null
                },
                {
                  name: 'Banner',
                  selector: 'courses',
                  cell: row =>
                    row.courses ? (
                      <img className="banners" alt="banner" src={row.courses} />
                    ) : null
                },
                {
                  name: 'Banner Certificado',
                  selector: 'certificate',
                  cell: row =>
                    row.certificate ? (
                      <img
                        className="banners"
                        alt="banner"
                        src={row.certificate}
                      />
                    ) : null
                },
                {
                  name: 'Data de Criação',
                  selector: 'created_at',
                  sortable: true,
                  format: row => Formaters.date(row.created_at)
                },
                {
                  name: 'Data de Atualização',
                  selector: 'updated_at',
                  sortable: true,
                  format: row => Formaters.date(row.updated_at)
                }
              ]}
              data={banners}
              persistTableHead
              progressPending={loading}
            />
          </Column>
        </Row>
      )
    }
  }, [banners, loading])

  // Banners
  return (
    <Modal ref={modalRef}>
      <Container>
        {TableMemo}
        {banners ? (
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{
              authenticate: banners.length > 0 ? banners[0].authenticate : '',
              courses: banners.length > 0 ? banners[0].courses : '',
              certificate: banners.length > 0 ? banners[0].certificate : ''
            }}
          >
            <Row>
              <Column xs="12" sm="5" md="5" lg="12">
                <Input
                  name="authenticate"
                  type="text"
                  label="Banners nas Telas de Authenticação"
                  placeholder="Url do Banner..."
                />
              </Column>
              <Column xs="12" sm="5" md="5" lg="12">
                <Input
                  name="courses"
                  type="text"
                  label="Banner dos Cursos"
                  placeholder="Url do Banner..."
                />
              </Column>
              <Column xs="12" sm="5" md="5" lg="12">
                <Input
                  name="certificate"
                  type="text"
                  label="Banner do Certificado"
                  placeholder="Url do Banner..."
                />
              </Column>
              <Column xs="12" sm="5" md="5" lg="6" className="padding-top">
                {validationsErrors &&
                  validationsErrors.map((e, i) => <p key={i}>{e}</p>)}
              </Column>
              <Column xs="12" sm="5" md="5" lg="12">
                <Button loading={loading} type="submit">
                  {banners.length > 0 ? 'Atualizar' : 'Criar'}
                </Button>
              </Column>
            </Row>
          </Form>
        ) : null}
      </Container>
    </Modal>
  )
}
