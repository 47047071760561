import styled from 'styled-components'
import ReactSelect from 'react-select'

export const Container = styled.div`
  .error {
    display: inline-block;
    color: var(--color-error);
    font-size: 14px;
  }
`

export const SelectCustom = styled(ReactSelect)`
  flex: 1 1 auto;

  & .Select__control--is-disabled {
    background-color: #2d9a68 !important;
    border-color: #2d9a68 !important;
  }

  & .Select__control--is-focused {
    background-color: #2d9a68 !important;
    border: 2px solid #121214 !important;
    box-sizing: border-box;
  }

  & .Select__input {
    color: #fff;
  }

  & .Select__dropdown-indicator {
    color: #fff;
  }

  & .Select__loading-indicator {
    color: #fff;
  }

  & .Select__control {
    background-color: #2d9a68;
    border-color: #121214;
    box-sizing: border-box;
    border: 2px;
    border-color: #2d9a68;
    border-radius: 5px;
    box-shadow: none;
    min-height: 50px;
    & .Select__clear-indicator {
      visibility: hidden;
    }
    & .Select__single-value {
      color: #fff;
    }
    & .Select__placeholder {
      color: #fff;
    }
    & .Select__indicator.Select__clear-indicator {
      color: #fff;
    }
    & .Select__value-container--is-multi {
      & .Select__multi-value {
        background-color: rgb(41, 41, 46);
        display: flex;
        margin: 2px;
        min-width: 0px;
        border-radius: 5px;
        box-sizing: border-box;
        transition: all 100ms ease 0s;

        & .Select__multi-value__label {
          border-radius: 5px;
          color: var(--color-white);
          font-size: 16px;
          padding: 8px;
          padding-left: 10px;
        }
        & .Select__multi-value__remove {
          color: var(--color-white);
          :hover {
            color: var(--color-green);
            background-color: var(--color-white);
          }
        }
      }
    }
    :hover {
      & .Select__clear-indicator {
        visibility: visible;
      }
      & .Select__indicator.Select__clear-indicator {
        color: #fff;
      }
    }
  }
  & .Select__menu {
    background-color: #2d9a68;
    & .Select__menu-list {
      padding: 0;
      border-radius: 5px;
      color: #fff;
      .Select__option.Select__option--is-selected {
        background-color: #278057;
      }
      .Select__option.Select__option--is-focused {
        background-color: #278057;
      }
    }
  }
`
