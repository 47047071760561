import React, { useState, useMemo } from 'react'

// Libs
import PropTypes from 'prop-types'

// Components
import { Sidebar, Header, Footer } from '../../../components'

// Styles
import { Container } from './styles'

// Routes
import { sideBarRoutes } from '../../../utils'

export const DefaultLayout = ({ children }) => {
  const [toggleState, setToggleState] = useState(false)

  const DefaultMemo = useMemo(() => {
    const toggle = () => setToggleState(!toggleState)

    return (
      <Container>
        <Header toggle={toggle} toggleState={toggleState} />
        <Sidebar
          toggle={toggle}
          toggleState={toggleState}
          routes={sideBarRoutes}
        />
        <main>{children}</main>
        <Footer />
      </Container>
    )
  }, [children, toggleState])

  return DefaultMemo
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired
}
