import styled from 'styled-components'

export const Container = styled.div`
  label {
    display: block;
    width: 100%;
    min-height: 16px;
    font-size: 14px;
    color: rgb(135, 134, 139);
    margin-bottom: 8px;
  }
`

export const Colors = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;

  width: 100%;
  height: 50px;
  outline: 0px;
  border-radius: 5px;
  padding: 15px 21px;
  transition: background 0.2s ease 0s;
  padding: 0px 1em 0px 1em;

  cursor: pointer;
  background: ${({ color }) => color && `${color}`};

  p {
    color: var(--color-white);
  }
`
export const Picker = styled.div`
  position: absolute;
  z-index: 2;

  .twitter-picker {
    top: 15px;
  }
`
export const ClosePicker = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`
