import styled from 'styled-components'

export const Container = styled.div`
  form {
    .label-journey {
      display: block;
      width: 100%;
      min-height: 16px;
      font-size: 14px;
      color: rgb(135, 134, 139);
      margin-bottom: 8px;
    }
  }
`

export const Content = styled.div`
  width: 100%;
  text-align: center;
  padding: 0 0 2rem 0;
  h5 {
    font-size: 1.5rem;
    color: var(--color-gray);

    strong {
      color: var(--color-green-light);
    }
  }
  .padding-top {
    padding-top: 1rem;
  }

  p {
    padding: 1rem;
    color: #d34242;
  }
`
