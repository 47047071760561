import { DASHBOARD_LIST_R, DASHBOARD_LIST_S, DASHBOARD_LIST_E } from './types'

// List
export const dashboardListR = () => ({
  type: DASHBOARD_LIST_R
})
export const dashboardListS = payload => ({
  type: DASHBOARD_LIST_S,
  payload
})
export const dashboardListE = () => ({
  type: DASHBOARD_LIST_E
})
