import { all } from 'redux-saga/effects'

import auth from './auth/sagas'
import users from './users/sagas'
import lessons from './lessons/sagas'
import courses from './courses/sagas'
import dashboard from './dashboard/sagas'
import banners from './banners/sagas'
import utils from './utils/sagas'

export default function* rootSaga() {
  return yield all([auth, users, lessons, courses, utils, dashboard, banners])
}
