import React, { useMemo, useState, useRef, useCallback } from 'react'

// Helpers
import { Formaters } from '../../../../helpers'

// Components
import { Modal, Table } from '../../../../components'

// SubComponents
import { Menu } from './menu'
import { Filter } from './filter'
import { CoursesModal } from './modal'
import { Expandable } from './expandable'

// Styles
import { Container, Content } from './styles'

export const CoursesTable = ({ data, loading }) => {
  // Hooks
  const modalRef = useRef(null)
  const [filter, setFilter] = useState('')
  const [editData, setEditData] = useState(null)
  const [select, setSelect] = useState({ value: 'title', label: 'Título' })
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false)

  const filteredItems = useCallback(() => {
    if (data)
      return data.filter(
        item =>
          item[select.value] &&
          item[select.value].toLowerCase().includes(filter.toLowerCase())
      )
  }, [data, filter, select])

  // Uses
  const handleOpenModal = useCallback(data => {
    if (data) setEditData(data)
    else setEditData(null)

    modalRef.current.toggleModal()
  }, [])

  const HeaderMemo = useMemo(() => {
    const handleClear = () => {
      if (filter) {
        setResetPaginationToggle(!resetPaginationToggle)
        setFilter('')
      }
    }

    return (
      <Filter
        selectData={[
          { value: 'title', label: 'Título' },
          { value: 'author', label: 'Autor' }
        ]}
        onSelect={e => setSelect(e)}
        onFilter={e => setFilter(e.target.value)}
        onClear={handleClear}
        filter={filter}
        onModal={handleOpenModal}
      />
    )
  }, [filter, resetPaginationToggle, handleOpenModal])

  return (
    <Container>
      <Content>
        <h1>Cursos</h1>
        {HeaderMemo}
      </Content>
      <Table
        columns={[
          { name: 'Título', selector: 'title', sortable: true },
          { name: 'Descrição', selector: 'description', sortable: true },
          { name: 'Autor', selector: 'author', sortable: true },
          { name: 'Ordem', selector: 'order', sortable: true },
          {
            name: 'Thumbnail',
            selector: 'thumbnail',
            cell: row =>
              row.thumbnail ? (
                <img
                  className="avatar-circular"
                  alt={row.name}
                  src={row.thumbnail}
                />
              ) : null
          },
          {
            name: 'Banner',
            selector: 'banner',
            cell: row =>
              row.banner ? (
                <img
                  className="avatar-circular"
                  alt={row.name}
                  src={row.banner}
                />
              ) : null
          },
          {
            name: 'Data de Criação',
            selector: 'created_at',
            sortable: true,
            format: row => Formaters.date(row.created_at)
          },
          {
            name: 'Data de Atualização',
            selector: 'updated_at',
            sortable: true,
            format: row => Formaters.date(row.updated_at)
          },
          {
            cell: row => (
              <Menu row={row} onModal={data => handleOpenModal(data)} />
            )
          }
        ]}
        data={filteredItems()}
        paginationResetDefaultPage={resetPaginationToggle}
        persistTableHead
        progressPending={loading}
        expandableRows
        expandableRowsComponent={<Expandable />}
        expandOnRowClicked
      />
      <Modal ref={modalRef}>
        <CoursesModal data={editData} />
      </Modal>
    </Container>
  )
}
