// Libs
import React, { useCallback, useState } from 'react'

// Components
import { ButtonGroups } from '../../../../../../components'

// SubComponents
import { SelectLessons } from './select'

// Styles
import { Container, Content } from './styles'

export const ModalEdit = ({ data }) => {
  // States
  const [active, setActive] = useState('selecione')

  // Mode Switch
  const handleActiveMode = useCallback(type => {
    setActive(type)
  }, [])

  return (
    <Container>
      <Content className="padding-top">
        <h5>
          Editando o Curso <strong>{data.title}</strong>
        </h5>
        <p>Selecione o modo de criação:</p>
        <ButtonGroups
          active={active}
          onActive={type => handleActiveMode(type)}
          types={['selecione']}
        />
      </Content>
      {active === 'selecione' && <SelectLessons data={data} />}
    </Container>
  )
}
