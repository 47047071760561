import { api } from './_httpClient'

const list = async () => api.get('/lesson/list')

const create = async payload => api.post('/lesson', payload)

const update = async payload => api.put('/lesson', payload)

const deleteU = async payload => api.delete('/lesson', { params: payload })

export { list, create, update, deleteU }
