import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--color-gray);

  transition: .4s ease-in-out;
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
  overflow-y: auto;
  ${({ toggleState }) =>
    toggleState
      ? `
        transform: translateX(0%);
        transition: .4s ease-in-out;
        `
      : `
        transform: translateX(-140%);
        `
      };

  @media only screen and (min-width: 1025px) {
    grid-area: sidebar;
    position: relative;
    transform: translateX(0%);
    overflow-y: hidden;
  }
`

export const MenuClose = styled.div`
  display: flex;
  justify-items: center;
  cursor: pointer;
  > svg {
    fill: var(--color-green-dark);
  }

  @media only screen and (min-width: 1025px) {
    display: none;
  }
`

export const LogoContainer = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  padding: 1rem;

  background-color: var(--color-background);

  img {
    height: 50px;
    width: 180px;
  }

  p {
    color: white;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
  }
`

export const Divider = styled.hr`
  height: 30px;
  border: 1px solid #fff;
`

export const PrimaryItem = styled.li`
  padding: 4em 1em 1em 1.75em;

  p {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    color: white;
  }
`

export const OuterItem = styled.li`
  padding: 1.2em 1em 1em 1.75em;
  transition: background 0.2s ease 0s, color 0.2s ease 0s;

  ${({ active }) =>
    active ? `background-color: var(--color-background);` : ''}

  &:hover {
    background-color: var(--color-background);
    cursor: pointer;
  }
`

export const OuterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;

  p {
    padding-left: 0.9em;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    transition: background 0.2s ease 0s, color 0.2s ease 0s;
    color: ${({ active }) => (active ? 'white' : '#a8a8b3')};
    &:hover {
      color: white;
    }
  }
`

export const OuterIcon = styled.div`
  display: flex;
  justify-content: center;

  > svg {
    color: white;
  }
`
