// Libs
import React, { useState, useRef, useEffect, useMemo } from 'react'
import { useField } from '@unform/core'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { components } from 'react-select'
import PropTypes from 'prop-types'

// Utils
import { arrayMove } from '../../../utils'

// Styles
import { Container, SelectCustom } from './styles'

export const Select = ({ name, drag, ...rest }) => {
  // Hooks
  const selectRef = useRef(null)
  const { fieldName, defaultValue, registerField, error } = useField(name)
  const [selected, setSelected] = useState()

  // Uses
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'value',
      getValue: ref => {
        if (rest.isMulti) {
          if (!ref.state.value && selected) {
            return selected
          }
        }
      },
      setValue: (ref, value) => {
        if (rest.isMulti) {
          if (value) {
            return setSelected(value)
          }
        } else {
          ref.select.setValue(value || null)
        }
      },
      clearValue: ref => {
        if(rest.isMulti) {
          return setSelected(null)
        }
        ref.select.clearValue()
      }
    })
  }, [fieldName, registerField, rest.isMulti, selected])

  const renderSelect = useMemo(() => {
    if (drag) {
      const SortableMultiValue = SortableElement(props => {
        const onMouseDown = e => {
          e.preventDefault()
          e.stopPropagation()
        }
        const innerProps = { onMouseDown }
        return <components.MultiValue {...props} innerProps={innerProps} />
      })
      const onSortEnd = ({ oldIndex, newIndex }) => {
        if (selected) {
          setSelected(arrayMove(selected, oldIndex, newIndex))
        }
      }

      const onChange = selectedOptions => setSelected(selectedOptions)

      const SortableSelect = SortableContainer(SelectCustom)

      return (
        <SortableSelect
          axis="xy"
          distance={4}
          getHelperDimensions={({ node }) => node.getBoundingClientRect()}
          defaultValue={defaultValue}
          classNamePrefix={'Select'}
          onChange={onChange}
          onSortEnd={onSortEnd}
          value={selected}
          components={{
            MultiValue: SortableMultiValue
          }}
          closeMenuOnSelect={false}
          ref={selectRef}
          {...rest}
        ></SortableSelect>
      )
    }

    return (
      <SelectCustom
        defaultValue={defaultValue}
        ref={selectRef}
        classNamePrefix={'Select'}
        {...rest}
      />
    )
  }, [selected, defaultValue, drag, rest])

  return (
    <Container>
      {renderSelect}
      {error && <span className="error">{error}</span>}
    </Container>
  )
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  drag: PropTypes.bool
}

Select.defaultProps = {
  drag: false
}
