import styled, { keyframes } from 'styled-components'

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`

export const Container = styled.div`
  animation: ${appearFromRight} 0.5s ease;
`
export const Content = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  @media only screen and (min-width: 1025px) {
    flex-direction: row;
    align-items: baseline;
  }
`
