import React from 'react'
import { BrowserRouter, Router } from 'react-router-dom'

// Libs
import { PersistGate } from 'redux-persist/integration/react'
import { ToastContainer } from 'react-toastify'
import { Provider } from 'react-redux'
import { ModalProvider } from 'styled-react-modal'

// Global Style
import { GlobalStyle } from './styles'
import 'react-toastify/dist/ReactToastify.css'
import 'sweetalert2/src/sweetalert2.scss'

// Configs Globals
import './config/ReactotronConfig'

// Routing
import { Routes } from './routes'

// Redux
import { store, persistor } from './store'

// Services
import { history } from './services'

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router history={history}>
          <BrowserRouter>
            <ModalProvider>
              <Routes />
            </ModalProvider>
            <GlobalStyle />
            <ToastContainer />
          </BrowserRouter>
        </Router>
      </PersistGate>
    </Provider>
  )
}

export default App
