import { api } from './_httpClient'

const list = async () => api.get('/course/list')

const create = async payload => api.post('/course', payload)

const update = async payload => api.put('/course', payload)

const deleteU = async payload => api.delete('/course', { params: payload })

export { list, create, deleteU, update }
