import React from 'react'

// Libs
import PropTypes from 'prop-types'

// Styles
import { Container, ButtonToggle } from './styles'

export const ButtonGroups = ({ active, onActive, types, ...rest }) => {
  return (
    <Container>
      {types.map((type, i) => (
        <ButtonToggle
          key={i}
          active={active === type}
          onClick={() => onActive(type)}
          {...rest}
        >
          {type}
        </ButtonToggle>
      ))}
    </Container>
  )
}

ButtonGroups.propTypes = {
  active: PropTypes.string,
  onActive: PropTypes.func,
  types: PropTypes.array
}
