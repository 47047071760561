import React, { useMemo } from 'react'

// Libs
import PropTypes from 'prop-types'

// Styles
import { Container, Wrapper, Content } from './styles'

export const AuthLayout = ({ children }) => {
  const AuthMemo = useMemo(() => {
    return (
      <Container>
        <Wrapper>
          <Content>{children}</Content>
        </Wrapper>
      </Container>
    )
  }, [children])
  return AuthMemo
}

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired
}
